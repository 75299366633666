@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.footer-bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.footer-bottom-text {
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, .5);

  span {
    font-size: 9px;
    color: $bg-header-dark;
  }

  @include mediaMax($sm) {
    display: none;
  }
}

.footer-bottom-links {
  display: flex;
  gap: 10px;

  @include mediaMax($sm) {
    display: flex;
    flex-direction: column;
  }
}

.footer-bottom-info {
  display: flex;
  gap: 30px;

  a {
    color: #ffff;
  }

  @include mediaMax($sm) {
    display: flex;
    flex-direction: column;
  }
}

.footer-bottom-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .2);
}

.footer-bottom-link-icon {
  height: 25px;
}