@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.trips-corporate-changes {
  margin: 20px 0;

  .title {
    text-align: center;
    font-size: clamp(24px, 3vw, 34px);
    margin: 0px 0 30px 0;
  }

  .trips-corporate-block {
    display: grid;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    padding: 16px;
    gap: 16px;
    position: relative;
    z-index: 111;
    cursor: pointer;
        @include mediaMax($lg)
{
      grid-template-columns: repeat(2, 1fr);
    }

    @include mediaMax($sm) {
      grid-template-rows: repeat(3, auto);
      padding: 16px;
    }

    .trips-corporate-status {
      p {
        text-align: right;

        &.dark-theme {
          color: $primary-background;
        }
      }
    }

    &.secondary-bg-dark-theme {

      .hr-line {
        border-color: $border-color-dark;
        opacity: 0.3;
      }
    }

    &.secondary-bg-light-theme {

      .hr-line {
        border-color: $border-color-light;
        opacity: 0.5;
      }
    }

    .hr-line {
      grid-column: 1/5;
      border-width: 1px;
      border-style: solid;

      @include mediaMax($lg) {
        grid-column: 1/3;
      }
    }

    .more-block {
      grid-column: 1/5;
      display: flex;
      justify-content: flex-end;

      @include mediaMax($lg) {
        grid-column: 1/3;
      }

      img {
        max-width: 100%;
        width: 26px;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transition: 300ms ease;
        -webkit-transition: 300ms ease;
        -moz-transition: 300ms ease;
        -ms-transition: 300ms ease;
        -o-transition: 300ms ease;
      }

      .arrow-close {
        transform: rotate(180deg);
      }

      .arrow-show {
        transform: rotate(0deg);
      }
    }
  }

  .trips-corporate-name {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include mediaMax($sm) {
      grid-column: 1/3;
    }

    p:nth-child(1) {
      font-weight: 600;
    }

    p:nth-child(2) {
      font-weight: 100;
      margin-bottom: 10px;

      @include mediaMax($lg) {
        margin-bottom: 0;
      }
    }

    p:nth-child(3) {
      font-weight: 100;
      opacity: 0.6;
    }
  }

  .trip-corporate-date {
    justify-self: center;

    @include mediaMax($lg) {
      justify-self: end;
      align-self: flex-start;
    }

    @include mediaMax($sm) {
      justify-self: flex-start;
      align-self: center;
    }

    p {
      @include mediaMax($sm) {
        text-align: center;
      }
    }
  }

  .trips-corporate-price {
    justify-self: center;

    @include mediaMax($lg) {
      justify-self: flex-start;
    }

    @include mediaMax($sm) {
      justify-self: end;
    }

    p {
      font-weight: 600;
    }
  }

  .trips-corporate-status {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    justify-self: end;
    align-items: center;

    @include mediaMax($sm) {
      grid-column: 1/3;
      justify-self: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    p {
      opacity: 0.6;
    }

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
}

    .yellow {
      background-color: #ff9f00;
    }

    .red {
      background-color: $error-color;
    }

    .green {
      background-color: $success-color;
    }

    .blue {
      background-color: $primary-color;
    }
  }
}

.orders-item {
  .order-actions {
    width: 96%;
    padding: 16px 32px;
    margin: 0 auto;
    box-shadow: 0px 0px 3px 0px #bebee4;
    border-radius: 0 0 10px 10px;
    -webkit-border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    -ms-border-radius: 0 0 10px 10px;
    -o-border-radius: 0 0 10px 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    transition: 300ms;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    margin-top: -8%;

    @include mediaMax($xl) {
      margin-top: -10.5%;
    }

    @include mediaMax($lg) {
      grid-template-columns: repeat(2, 1fr);
      padding: 16px;
      margin-top: -26%;
    }

    @include mediaMax($md) {
      margin-top: -23%;
    }

    @include mediaMax($sm) {
      padding: 8px;
      gap: 8px;
      margin-top: -118px;
    }

    &.open {
      margin-top: 0;
      background: #004daa1a;
      border: 1px solid rgb(0 74 173 / 20%);
      border-top-width: 0;
    }

    button {
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 8px;
      color: #ffffff;
      font-weight: bold;
      border: none;
      display: grid;
      align-items: center;
      grid-template-columns: 30px 1fr;
      border: 1px solid #fff;
      cursor: pointer;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 4px 0 #0000003b;

      &:active {
        box-shadow: none;
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        box-shadow: none;

        &:active {
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }
      }

      .btn-icon {
        max-width: 100%;
        width: 24px;
        height: auto;
        fill: #ffffff;
      }

      span {
        border-left: 1px solid #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .pay-order {
      background-color: #0049ad;
    }

    .cansel-order {
      background-color: #ff6200;
    }

    .download-document {
      background-color: #00AA84;

      svg {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }
    }

    .copy-order-link {
      background-color: #ff9f00;
    }
  }
}