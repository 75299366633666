@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.booking-passenger-info-wrapper {
  grid-column: 1/2;

  .passenger-info-wrapper {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    padding: 10px 30px;
    grid-column: 1/2;
    position: relative;
    border: none;
    box-shadow: none;

    .passenger-form {
      margin-bottom: 40px;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 24px;
    }

    @include mediaMax($sm) {
      padding: 20px 15px;
    }

    ._passenger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding: 6px 0;

      @include mediaMax($sm) {
        height: 80px;
        flex-direction: column;
        margin-bottom: 16px;
      }

      .passenger-btn {
        position: absolute;
        right: 0;
        display: flex;
        gap: 16px;
        animation: hideSaveBtm 0.5s forwards;
        -webkit-animation: hideSaveBtm 0.5s forwards;

        @include mediaMax($sm) {
          bottom: 0;
        }

        &.show-save-button {
          animation: showSaveBtm 0.5s forwards;
          -webkit-animation: showSaveBtm 0.5s forwards;
        }

        .employeer-button {
          background-color: transparent;
          width: 150px;
          font-size: 12px;
        }

        @keyframes hideSaveBtm {
          from {
            right: 0;
          }

          to {
            right: -166px;
          }
        }

        @keyframes showSaveBtm {
          from {
            right: -166px;
          }

          to {
            right: 0;
          }
        }
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;

      @include mediaMax($sm) {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
    }

    label {
      color: #62657F;
      font-size: 14px;
      font-weight: 400;
    }

    input:required {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      padding: 11.5px 13px 9.5px 13px;
      border: 1px solid #D0D1D7;
    }

    input:required {
      color: $error-color;
      font-size: 50px;
    }

    select {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      padding: 9.5px 13px 9.5px 13px;
      width: 100%;
    }

    .passenger-input {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .first-second-column {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 30px;

      @include mediaMax($sm) {
        grid-template-columns: 1fr;
      }

      @media screen and (max-width: 640px) {
        flex-direction: column;
        width: 100%;
      }

      .first-column {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
      }

      .second-column {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
      }
    }

    .gender {
      display: flex;
      gap: 8px;

      button {
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        padding: 11.5px 13px 9.5px 13px;
        border: 1px solid #D0D1D7;
        cursor: pointer;
        font-size: 16px;
        //color: #62657F;
        width: 100%;

        &.no-select {
          background-color: transparent;
          color: $text-color-night;
        }
      }
    }

    img {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 0 0 30px 0;
      -webkit-border-radius: 0 0 30px 0;
      -moz-border-radius: 0 0 30px 0;
      -ms-border-radius: 0 0 30px 0;
      -o-border-radius: 0 0 30px 0;
      height: clamp(100px, 16vw, 200px);
    }
  }

  .passenger-item {
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px;
    margin-bottom: 24px;
    display: grid;

    &.dark-passenger-theme {
      border-color: $border-color-dark;

      .citizenship {
        border-color: $border-color-dark;
      }

      p {
        border-bottom-color: $border-color-dark;
      }
    }

    &.light-passenger-theme {
      border-color: $border-color-light;

      .citizenship {
        border-top-color: $border-color-light;
      }

      p {
        border-bottom-color: $border-color-light;
      }
    }

    h3,
    .emloyeer-buttons {
      margin-top: 6px;
      margin-bottom: 16px;
    }

    .citizenship {
      border-top-width: 2px;
      border-top-style: dashed;
    }

    p {
      border-bottom-width: 2px;
      border-bottom-style: dashed;
      padding: 8px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      gap: 6px;
    }

    .emloyeer-buttons {
      margin-top: 16px;
      margin-bottom: 6px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 16px;

      .delete-icon {
        background-color: #e95454;
        width: 36px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          max-width: 100%;
          width: 26px;
          height: 26px;
        }

        .reload-icon {
          width: 22px;
          height: 22px;
          animation: rotate 2s linear infinite;
          -webkit-animation: rotate 2s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
          }

          to {
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
          }
        }
      }
    }
  }

  .modal-passengers {
    width: 100% !important;

    .loading-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;
    }

    header {
      position: sticky;
      top: 0;
      padding-bottom: 16px;
      border-bottom: 2px solid #f2f2f2;
      margin-bottom: 16px;
      z-index: 10;

      &.dark-passengers-header {
        background-color: $secondary-bg-dark;
      }

      &.light-passengers-header {
        background-color: #fff;
      }

      .inputs {
        margin-top: 16px;

        input {
          padding: 8px 12px;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          outline: none;
          width: 100%;
          font-size: 16px;
        }
      }
    }

    .no-users {
      h3 {
        margin: 32px 0;
        text-align: center;
        line-height: 1.4;
        font-size: 17px;
      }

      .user-is-not-found {
        width: 200px;
        margin: 32px auto;
        padding-top: 32px;
        display: block;

        path {
          fill: $primary-color;
        }
      }
    }
  }
}