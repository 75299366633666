@import "../../../styles/variables.scss";

.theme-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 11px;
  margin: 10px;
  font-size: medium;

  .theme-toggle-div-bg {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    img {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      padding: 1.5px;
    }
  }

  img {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

  p {
    &.light-theme {
      color: black;
    }

    &.dark-theme {
      color: $primary-background;
    }
  }
  transition: ease 0.25ms;
  -webkit-transition: ease 0.25ms;
  -moz-transition: ease 0.25ms;
  -ms-transition: ease 0.25ms;
  -o-transition: ease 0.25ms;
}