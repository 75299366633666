@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

@mixin themeColorHandle($active-bg, $active-color, $nav-bg, $burger-color) {
  .link-a {

    &:hover {
      background-color: $active-bg;
      color: $active-color;
    }

    &.active {
      background-color: $active-bg;
      color: $active-color;
    }
  }

  .nav-links {
    background-color: $nav-bg;
  }

  .burger-button {

    span {
      background-color: $burger-color;
    }
  }
}

.header-nav-pages {
  grid-column: 1/3;
  justify-self: center;
  width: 100%;
  border: none;
  box-shadow: none;

  &.secondary-bg-dark-theme {
    @include themeColorHandle($primary-color-dark, $text-color-day, $secondary-bg-dark, white);
  }

  &.secondary-bg-light-theme {
    @include themeColorHandle($primary-color, $text-color-night, $secondary-bg-light, black);
  }

  @include mediaMax($md) {
    height: $search-input-heigth;
  }

  .header-nav-pages-wrap {
    position: relative;


    @include mediaMax($md) {
      padding: 0;
    }

    .burger-button {
      display: none;

      @include mediaMax($md) {
        display: block;
        cursor: pointer;
        width: 18px;
        height: 16px;
        position: absolute;
        right: 0;
        margin: 15px;
        transform: translateY(6px);
        -webkit-transform: translateY(6px);
        -moz-transform: translateY(6px);
        -ms-transform: translateY(6px);
        -o-transform: translateY(6px);

        span {
          display: block;
          height: 2px;
          width: 100%;
          transition: 200ms ease;
          -webkit-transition: 200ms ease;
          -moz-transition: 200ms ease;
          -ms-transition: 200ms ease;
          -o-transition: 200ms ease;
          position: absolute;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2) {
            top: 7px;
          }

          &:nth-child(3) {
            bottom: 0;
          }
        }

        &.is-open {
          height: 20px;
          width: 20px;
          transform: translateY(4px);
          -webkit-transform: translateY(4px);
          -moz-transform: translateY(4px);
          -ms-transform: translateY(4px);
          -o-transform: translateY(4px);
        }

        &.is-open :nth-child(1) {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          top: 9px;
        }

        &.is-open :nth-child(2) {
          width: 0;
        }

        &.is-open :nth-child(3) {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          bottom: 9px;
        }
      }
    }

    .nav-links {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      justify-content: space-between;
      justify-content: space-evenly;
      gap: 100px;
      height: 26px;

      .link-a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      @include mediaMax($md) {
        display: none;
        grid-auto-flow: row;
        padding: 40px 0;
        top: 58px;
        position: absolute;
        width: 100%;
        transition: 300ms;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -ms-transition: 300ms;
        -o-transition: 300ms;
        gap: 24px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        animation: menuDropdownOut 0.5s forwards, menuFadeOut 0.5s forwards;
        -webkit-animation: menuDropdownOut 0.5s forwards, menuFadeOut 0.5s forwards;

        &.show-nav-links {
          display: grid;
          height: 30vh;
          animation: menuDropdownIn 0.2s forwards, menuFadeIn 0.2s forwards;
          -webkit-animation: menuDropdownIn 0.2s forwards, menuFadeIn 0.2s forwards;
        }

        @keyframes menuFadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @keyframes menuFadeOut {
          from {
            opacity: 1;
          }

          to {
            opacity: 0;
          }
        }

        @keyframes menuDropdownIn {
          0% {
            transform: translateY(-100px);
            -webkit-transform: translateY(-100px);
            -moz-transform: translateY(-100px);
            -ms-transform: translateY(-100px);
            -o-transform: translateY(-100px);
          }

          to {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
          }
        }

        @keyframes menuDropdownOut {
          0% {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
          }

          to {
            transform: translateY(-100px);
            -webkit-transform: translateY(-100px);
            -moz-transform: translateY(-100px);
            -ms-transform: translateY(-100px);
            -o-transform: translateY(-100px);
          }
        }
      }

      .balance-link {
        position: relative;
        cursor: pointer;
        color: #252525;
        font-size: 16px;
      }

      .sub-menu {
        width: 300px !important;
        min-width: min-content !important;
        left: 200px;

        @include mediaMax($xl) {
          left: 127px;
        }

        @include mediaMax($lg) {
          left: 50px;
        }

        @include mediaMax($md) {
          top: 110px;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          min-width: 96% !important;
          width: 96% !important;
        }

        .dropdown-block {
          padding: 0;
        }

        .balance {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(3, auto);
          gap: 24px;
          position: relative;
          background-color: $primary-color;
          color: #fff;
          padding-top: 24px;
          border-radius: 0 0 12px 12px;
          -webkit-border-radius: 0 0 12px 12px;
          -moz-border-radius: 0 0 12px 12px;
          -ms-border-radius: 0 0 12px 12px;
          -o-border-radius: 0 0 12px 12px;

          @include mediaMax($md) {
            text-align: center;
          }

          span {
            display: block;
            padding: 0 24px;
          }

          .wallet {
            position: absolute;
            right: 0;
            top: -10px;

            @include mediaMax($md) {
              display: none;
            }
          }

          a {
            display: block;
          }

          .third-block {
            background-color: #fff;
            padding: 24px 0;
            border-radius: 0 0 10px 10px;
            -webkit-border-radius: 0 0 10px 10px;
            -moz-border-radius: 0 0 10px 10px;
            -ms-border-radius: 0 0 10px 10px;
            -o-border-radius: 0 0 10px 10px;
          }
        }
      }
    }

    nav {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        font-size: 16px;
        transition: border-bottom 0.3s ease;
        -webkit-transition: border-bottom 0.3s ease;
        -moz-transition: border-bottom 0.3s ease;
        -ms-transition: border-bottom 0.3s ease;
        -o-transition: border-bottom 0.3s ease;
      }
    }
  }
}

@include mediaMax($md) {
  .isSubMenu {
    :nth-child(3) {
      margin-top: 174px;
      transition: 300ms ease;
      -webkit-transition: 300ms ease;
      -moz-transition: 300ms ease;
      -ms-transition: 300ms ease;
      -o-transition: 300ms ease;
    }
  }
}