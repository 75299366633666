@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.title-h2 {
  font-size: 55px;
  color: $primary-text-color;

  @include mediaMax($xl) {
    font-size: 55px;
  }

  @include mediaMax($lg) {
    font-size: 48px;
  }

  @include mediaMax($md) {
    font-size: 38px;
  }

  @include mediaMax($sm) {
    font-size: 30px;
  }

  &.white {
    color: $primary-background;
  }
}