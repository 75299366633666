@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.we-are-trusted-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 40px;

  .we-are-trusted-header {
    h2 {
      margin-bottom: 10px;
      text-align: center;
      color: #272727;
      font-size: 35px;
      line-height: 1.2;
      font-weight: 700;

      &.dark-theme {
        color: white;
      }
    }

    p {
      margin-bottom: 20px;
      color: #272727;
      font-size: 24px;
      line-height: 1.3;
      font-weight: 500;

      &.dark-theme {
        color: white;
      }
    }
  }

  .we-are-trusted-image-one {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    gap: 20px;

    img {
      height: 110px; // Автоматический подбор высоты для сохранения пропорций изображения
      padding: 30px;
    }
  }

  .we-are-trusted-footer {
    margin: 50px;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;

    @include mediaMax ($sm) {
      font-size: 10px;
    }

    @include mediaMax ($md) {
      font-size: 13px;
    }

    @include mediaMax ($lg) {
      font-size: 15px;
    }
  }

  img {
    width: 217px;
    height: 172.5px;
  }
}