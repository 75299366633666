@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";


.articles {
  .content {
    margin: clamp(30px, 7vw, 100px) auto;
  
    div {
      > :nth-child(2) {
        display: grid;
  
        span {
          line-height: 1.5;
        }
      }
  h2 {
    text-align: center;
    margin-bottom: 30px;
    color: var(--Black, #000);
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
  }

      img[src="https://bft-alpha.55fly.ru/uploads/bodyContent/fly_tj_logo (2)_1635748489.png"] {
        display: none;
      }
    }
    span {
      color: #656565;
      background-color: transparent !important;
      font-size: clamp(16px, 2vw, 20px);
    }
  
    strong {
      color: $primary-color;
    }
  }
}