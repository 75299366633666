@import "../../../styles/variables.scss";

.icon-with-text {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  font-size: clamp(14px, 2vw, 18px);
  align-items: center;

  img {
    width: 14px;
    height: 14px;
  }

  &.error {
    color: $error-color;
  }

  &.hold {
    color: $primary-color;
  }

  &.return {
    color: #FF9F00;
  }

  &.primary-text-color {
    color: $primary-text-color;
  }

  &.sucsses {
    color: $success-color;
  }

  .img-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}