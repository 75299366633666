@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@mixin themeColorHandler($input-bg, $text-color, $input-background) {
  background-color: $input-bg;

  input {
    color: $text-color;
  }

  .code-and-clear {
    background-color: $input-background;
  }

  .input-placeholder {
    color: $text-color;
  }
}

.avia-search-airport-input-wrapper {
  width: 100%;
  height: 54px;
  position: relative;
  transition: background-color .3s ease;
  border: 1px solid rgb(0 0 0 / 35%);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  &.avia-search-dark {
    @include themeColorHandler($input-background-dark, $text-color-night, $input-background-dark);
  }

  &.avia-search-light {
    @include themeColorHandler(#ffffff, $text-color-day, #ffffff);
  }

  &.not-filled {
    background-color: #f5d1d1;
    transition: background-color .3s ease;
  }

  &.advanced {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    &.last {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    @include mediaMax($lg) {}

    @include mediaMax($md) {
      &.from.first {
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
      }

      &.from.last {
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
      }
    }

    @include mediaMax($sm) {
      &.from.first {
        border-top-right-radius: 12px;
      }

      &.from.last {
        border-bottom-left-radius: 0;
      }
    }
  }

  .input-placeholder {
    padding: $search-input-paddings;
    height: $search-input-heigth;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;

    &.not-empty {
      padding: 4px 15px 0 20px;
      font-size: 11px;
    }
  }

  .input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 60px 0 20px;
    font-size: 14px;
    color: #333;
    background: none;
    border: none;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .code-and-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px;
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);

    &>span {
      font-size: 14px;
      color: #797C9A;
      line-height: 1;

    }

    &>button {
      display: flex;
      background-color: transparent;
      border: none;


      &>img {
        cursor: pointer;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        width: 15.75px;
        height: 13.5px;
      }
    }

  }

  .airports-dropdown {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 550px;
    overflow-y: auto;


    .airport-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      border: none;
      gap: 5px;
      padding: 5px 15px 5px 30px;
      cursor: pointer;


      &:hover {
        background-color: rgba(0, 74, 173, 0.1);
      }

      &>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 5px;
        font-size: 14px;
        color: #000;
        font-weight: 700;

        &.dark-theme {
          color: $text-color-night;
        }

        &>span {
          color: #AAB9C1;
        }
      }

      &>span {
        font-size: 12px;
        color: #AAB9C1;
      }

      &.main {
        padding: 5px 15px;
      }
    }
  }
}