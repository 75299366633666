@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.avia-booking-wrapper {
  padding: 40px 0;

  .booking-page {
    display: grid;
    grid-template-columns: 70% 1fr;
    gap: 20px;
    position: relative;

    @include mediaMax($lg) {
      display: flex;
      flex-direction: column;
    }

    .booking-page-info {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @include mediaMax($lg) {
        order: 2;
      }
    }
  }
}
