@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.title-h3 {
  text-align: center;
  margin: clamp(16px, 3vw, 36px) auto;
  font-size: 40px;
  color: $primary-color;
  font-weight: 600;
  &.dark-theme{
    color: white;
  }

  @include mediaMax($lg) {
    font-size: 34px;
  }

  @include mediaMax($md) {
    font-size: 28px;
  }

  @include mediaMax($sm) {
    font-size: 24px;
  }

  &.white {
    color: $primary-background;
  }

  &.text-left {
    text-align: left;
  }
}