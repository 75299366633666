@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.thanks-for-buying-page {
  margin: 60px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 48px;

  @include mediaMax($sm) {
    grid-template-columns: 1fr;

    img {
      width: 30%;
    }
  }

  .text-block {

    h1 {
      font-size: clamp(26px, 4vw, 48px);
      margin-bottom: clamp(26px, 4vw, 48px);
      color: #5d5d5d;
    }

    ul {
      list-style: none;
 
      li:nth-child(1) {
        color: $success-color;
      }

      li:nth-child(2) {
          color: $secondary-color;
      }

      li {
        margin-bottom: 16px;
        
        span {
          margin-left: 6px;
        }
      }
    }
  }

  button {
    grid-column: 1/3;
  }

  @include mediaMax($sm) {
    button {
      grid-column: 1/2;
    }
  }
}