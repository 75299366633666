@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@mixin themeColorHandle($active-btn-color, $active-text-color, $text-color, $border-color) {

  .month-buttons {
    color: $text-color;
    border-color: $border-color;

    &.activeMonth {
      background-color: $active-btn-color;
      color: $active-text-color;
      border-color: $active-btn-color;
    }

    &:hover {
      background-color: $active-btn-color;
      color: $active-text-color;
      border-color: $active-btn-color;
    }
  }
}
.month-navigations {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  width: 1300px;

  &.dark-theme-month-navigations {
    @include themeColorHandle($primary-color-dark, $text-color-day, $text-color-night, $border-color-dark);
  }

  &.light-theme-month-navigations {
    @include themeColorHandle($primary-color, $text-color-night, $text-color-day, $border-color-light);
  }

  .month-buttons {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    font-size: 15px;
    transition: 300ms ease;
    -webkit-transition: 300ms ease;
    -moz-transition: 300ms ease;
    -ms-transition: 300ms ease;
    -o-transition: 300ms ease;

    &:active {
      transform: scale(0.99);
      -webkit-transform: scale(0.99);
      -moz-transform: scale(0.99);
      -ms-transform: scale(0.99);
      -o-transform: scale(0.99);
    }
  };
}