@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.corporate-my-profile-wrapper {
  &.dark-theme {

    h1,
    p,
    span {
      color: $text-color-night;
    }
  }

  .logo-profile-company {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto auto auto;
    width: 200px;
    height: 200px;
    padding: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    .visually-hidden {
      display: none;
    }

    &.dark-theme {
      background-color: $body-order-cards-bg;
    }

    @include mediaMax($sm) {
      margin-top: 40px;
    }

    img {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      max-width: 100%;
    }
  }

  h1 {
    font-size: 30px;
    text-align: center;
    margin: 40px 0;

    @include mediaMax($sm) {
      font-size: 24px;
      margin: 30px auto;
    }
  }

  .corporate-my-profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    gap: 30px;

    @include mediaMax($md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include mediaMax($sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    input {
      margin-top: 10px;
      border: none;
      box-shadow: 0 0 2px 0px #0000ff3d;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      font-size: 20px;
      padding: 10px;

      @include mediaMax($md) {}

      @include mediaMax($sm) {}
    }

    .corporate-my-profile-info {
      display: grid;
      width: 100%;
      height: 100px;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
      padding: 16px;
            &.dark-theme
{
        background-color: $body-order-cards-bg;
      }

      .input-password {
        width: 100%;
      }

      .eye-off {
        width: 30px;
      }

      .eye-on {
        width: 30px;
        opacity: 0.6;
      }

      .eyes-input-password {
        display: flex;
        position: relative;
      }

      .eyes-on-off {
        position: absolute;
        top: 33px;
        right: 8px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }

      span {
        opacity: 0.6;
        font-weight: 100;
      }

      p {
        padding: 16px;
        font-weight: 600;
        font-size: 19px;
      }
    }
  }

  .corporate-my-profile-button {
    text-align: center;
    margin: 40px 0;

    button {
      width: 200px;
      height: 50px;
      font-size: 20px;
    }
  }
}