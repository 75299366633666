@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.avia-order-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 0;

  & .title {
    font-size: 32px;
    font-weight: 600;
    color: #2C3959;
    margin-bottom: 5px;
    @include mediaMax($sm) {
     font-size: 30px;
    }
  }

  & .book-status {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;

    .created-text {
      color: #9A9EB1;
    }
  }

  & .payment-attention {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #B70000;
    margin-bottom: 20px;
  }

  & .info-and-price {
    display: grid;
    grid-template-columns: 70% 1fr;
    gap: 20px;
    position: relative;

    @include mediaMax($xl) {
      grid-template-columns: 65% 1fr;
    }

    @include mediaMax($lg) {
      grid-template-columns: 1fr;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 25px;

      & .modal-body {
        overflow: unset !important;
      }
    }

    .price {
      position: relative;

      & .modal-body {
        overflow: unset !important;
      }
    }
  }

  .checkbox {
    display: flex;
    gap: 10px;
    padding: 0px 20px;
  }

  a {
    color: $primary-color;
    cursor: pointer;
  }
}