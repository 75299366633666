@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.agreement-wrapper {
  margin: 30px 0;

  .agreement-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .agreement-info {
      display: flex;
      gap: 300px;

      &.dark-theme {
        color: white;
      }

      @include mediaMax($md) {
        display: grid;
        grid-template-rows: 1fr 2fr;
        gap: 0;
      }

      @include mediaMax($sm) {
        display: grid;
        grid-template-rows: 1fr 2fr;
        gap: 0;
      }
    }

    .input-text {
      display: flex;
      align-items: end;
    }

    .company {
      width: 270px;
    }

    input {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 300px;
      text-align: center;
      font-size: 18px;
      margin-top: 5px;
      margin-left: 10px;
      border: none;
      border-bottom: 0.5px solid $secondary-text-color;
      outline: none;
      background: transparent;
      padding: 4px 8px;
      cursor: pointer;

      &.dark-theme {
        border-bottom: 0.5px solid $border-bottom;
      }

      &::placeholder {
        color: $primary-text-color;
      }
    }
  }

  input.dark-theme {
    &::placeholder {
      color: $text-color-night;
    }
  }

  h1 {
    text-align: center;
    font-size: clamp(24px, 3vw, 34px);
    margin: 30px 0 30px 0;

    &.dark-theme {
      color: $text-color-night;
    }
  }

  .agreement-box {
    display: flex;
    gap: 25px;


    button {
      border: none;
      background: none;
      position: relative;
    }

    @include mediaMax($md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include mediaMax($sm) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: relative;
    width: 250px;
    height: 280px;
        &.dark-theme
{
      background-color: $body-download-card-dark;
    }

    p {
      font-size: 13px;
      padding: 5px;
      font-style: italic;

      &.dark-theme {
        color: white;
      }
    }

    .img {
      align-self: center;
      width: 220px;
      height: 210px;
    }

    .arrow {
      width: 70px;
      position: absolute;
      top: 90px;
      left: 90px;
    }

    span {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-background;
      background-color: #3679D6;
      width: 100%;
      height: 60px;
      border: none;
      position: absolute;
      bottom: 0px;
      border-radius: 2px 2px 10px 10px;
      -webkit-border-radius: 2px 2px 10px 10px;
      -moz-border-radius: 2px 2px 10px 10px;
      -ms-border-radius: 2px 2px 10px 10px;
      -o-border-radius: 2px 2px 10px 10px;
}
  }

  .agreement-button {
    button {
      width: 250px;
      height: 40px;
      font-size: 15px;
    }
  }
}