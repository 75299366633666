@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.corporate-table-fines {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaMax($lg) {
    overflow-x: scroll;
  }

  .corporate-fines-title {
    display: block;

    th {
      padding: 8px;
      width: 260px;
      background-color: rgb(56 102 163);
      color: #fff;
      vertical-align: middle;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    min-width: 230px;
    border: 1px solid #ddd;
    text-align: center;
    letter-spacing: 0.4px;
    padding: 10px;
    &.light-theme{
      color: black;
    }
    &.dark-theme{
      color: rgb(255, 255, 255);
    }
  }
}