@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@mixin themeColorHandler($input-bg, $text-color, $placeholder-color ) {
  .from-date-block {
    background-color: $input-bg;

    .input-date {
      color: $text-color;
    }
  }

  .to-date-block {
    background-color: $input-bg;

    .input-date {
      color: $text-color;
    }
  }

  .input-placeholder {
    color: $placeholder-color;
  }
}

.avia-search-dates-inputs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  &.dark-theme-search-dates {
    @include themeColorHandler($input-background-dark, $text-color-night, $text-color-night)
  }

  &.light-theme-search-dates {
    @include themeColorHandler(#ffffff, $text-color-day, $text-color-day)
  }

  @include mediaMax($sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &.advanced {
    grid-template-columns: 1fr;
    height: 100%;
  }

  .from-date-block {
    position: relative;
    //background-color: #fff;
    border: 1px solid #00000047;
    transition: background-color .3s ease;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -ms-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    &.notFilled {
      background-color: #f5d1d1;
      transition: background-color .3s ease;
      -webkit-transition: background-color .3s ease;
      -moz-transition: background-color .3s ease;
      -ms-transition: background-color .3s ease;
      -o-transition: background-color .3s ease;
    }

    &.advanced {
      width: 100%;
    }
  }

  .to-date-block {
    position: relative;
    background-color: #fff;
    height: $search-input-heigth;
    cursor: pointer;
    border: 1px solid #00000047;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .input-placeholder {
    padding: $search-input-paddings;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;

    &.not-empty {
      padding: 4px 15px 0 20px;
      font-size: 11px;
    }
  }

  .input-date {
    padding: 23px 15px 0 20px;
    font-size: 14px;
  }

  .clear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
    z-index: 100;

    &>img {
      cursor: pointer;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      width: 15.75px;
      height: 13.5px;
    }
  }

  .from-right {
    right: 50%;
  }
}