@import "./variables.scss";

button.button {
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
}