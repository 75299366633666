@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.trip-corporate-wrapper {
  padding: 60px 0;
  background-attachment: fixed;

  &.dark-theme{

    h1 {
      color: $text-color-night;
    }
  }

  .orders-item {
    margin-bottom: 24px;
  }

  .orders-item-section {
    display: grid;
    grid-template-columns: 1fr 22%;
    gap: 24px;

    @include mediaMax($xl) {
      grid-template-columns: 1fr 26%;
    }

    @include mediaMax($lg) {
      grid-template-columns: 1fr 33%;
    }

    @include mediaMax($md) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    margin-bottom: 20px;
  }
}