@import "fonts";
@import "common";
@import "buttons";
@import './variables.scss';
@import "./mixins.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  max-width: 100vw;
  min-width: 360px;
  height: 100%;
  color: rgb(64, 75, 90);
  overflow-x: hidden;
}

body > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

small {
  color: $small-tag-color;
}

.header-wrapper {
  display: flex;
}

.input-range__label{
  display: none;
}

.input-range__slider{
  background: #fff;
  border: 2px solid $primary-color;
}

.input-range__track--active{
  background: $primary-color;
}

._interval {
  height: 80px;
}

input, select, option {
  outline: none;
  cursor: pointer;
}

img, svg {
  max-width: 100%;
}