@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.avia-result-wrapper {
  display: flex;
  gap: 50px;
  padding: 50px 0;
  position: relative;

  @include mediaMax($md) {
    .avia-result-block {
      width: 100%;
    }
  }

  @include mediaMax($xl) {
    gap: 30px;
  }

  @include mediaMax($lg) {
    flex-direction: column;
    align-items: center;
  }

  .number-of-tickets {
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: $primary-color;
    font-weight: 600;

    @include mediaMax($sm) {
      font-size: 14px;
    }

    &.dark-theme {
      color: $primary-color-dark;
    }
  }

  .results {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 950px;
    margin-top: 24px;

    @include mediaMax($xl) {
      width: 800px;
    }

    @include mediaMax($md) {
      width: 100%;
    }
  }

  .show-more-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    .show-more-button {
      background-color: $primary-color;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      color: #fff;
      border: none;
      font-size: 20px;
      font-weight: 500;
      padding: 10px 25px;
      cursor: pointer;
    }
  }
}