@import "../../styles/mixins.scss";

@mixin themeColorHandle($header-bg) {
  background-color: $header-bg;
}
header {
  position: sticky;
  top: 0;
  z-index: 150;
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.25);

  &.dark-theme-header{
    @include themeColorHandle(black)
  }

  &.light-theme-header{
    @include themeColorHandle(white)
  }

  hr {
    background-color: rgb(193 193 193 / 37%);
    height: 1px;
    border: none;
  }

  .header-wrapper {
    grid-template-columns: 2.5fr 1.5fr 1.6fr;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    @include mediaMax($sm) {
      grid-template-columns: repeat(3, 1fr);
      height: 80px;
    }
    @include mediaMax($md) {
      grid-template-columns: repeat(3, 1fr);
      height: 80px;
    }
    .balance{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
