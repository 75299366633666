@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.booking-route-details-wrapper {
  .routes {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &.routes-dark {
      background-color: $secondary-bg-dark;
    }

    .route {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-top: 1px solid #F5F5F7;
      padding-top: 30px;

      .route-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .route-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
        }
      }

      .segment {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .transfer {
          padding: 15px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;

          .transfer-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 5px;
          }

          .transfer-text {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .segment-info {
          display: flex;
          gap: 20px;

          @include mediaMax($sm) {
            flex-direction: column;
            gap: 10px;
          }

          .flight {
            display: flex;
            gap: 10px;

            @include mediaMax($sm) {
              width: 100%;
            }

            .from,
            .to {
              width: 120px;

              .time {
                font-size: 24px;
                line-height: 36px;
              }

              .small-text {
                font-size: 11px;
                line-height: 18px;
                color: #797C9A;
              }

              @include mediaMax($md) {
                width: 90px;
              }
            }

            .from {
              text-align: right;
            }

            .supplier-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              flex-grow: 1;
              gap: 5px;

              .small-text {
                display: none;
              }

              @include mediaMax($sm) {
                .small-text {
                  display: block;
                  font-size: 11px;
                  line-height: 18px;
                  color: #797C9A;
                  text-align: center;
                }
              }

              .supplier {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 12px;
                line-height: 18px;
                color: #797C9A;

                @include mediaMax($sm) {
                  img {
                    display: none;
                  }
                }
              }

              .fly-line {
                width: 450px;
                height: 4px;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                background-color: $primary-color;

                @include mediaMax($xl) {
                  width: 300px;
                }

                @include mediaMax($md) {
                  width: 150px;
                }

                @include mediaMax($sm) {
                  width: 100%;
                }
              }
            }
          }

          .duration-and-flight {
            @include mediaMax($sm) {
              display: none;
            }

            .time {
              font-size: 24px;
              line-height: 36px;
            }

            .small-text {
              font-size: 13px;
              line-height: 18px;
              color: #797C9A;
            }
          }
        }
      }

      .button-and-type-class {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
      }
    }
  }

  .booking-page-bg {
    background-color: #F0F0F1;
    padding: 60px 0;
  }

  .info-body {
    display: none;

    &.show-details {
      display: block;
    }
  }

  .route-details {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    padding: 50px 30px;

    @include mediaMax($sm) {
      padding: 20px 15px;
    }

    .more-info-button {
      border: none;
      background-color: transparent;
      padding: 8px 15px;
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      cursor: pointer;
    }

    h3 {
      display: block;
      line-height: 1.6;
      font-size: 15px;
    }

    span {
      display: block;
      line-height: 1.6;
    }

    p {
      line-height: 1.6;
    }

    h3 {
      margin-bottom: 8px;
    }

    h4 {
      font-size: 23px;
      margin-bottom: 10px;
      font-weight: 400;
    }

    .row-tree {
      display: grid;
      gap: 16px;
      grid-template-columns: 400px 100px 170px;
      margin: 60px auto;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .item-1 {
          grid-column: 1/3;
          grid-row: 1/2;
        }

        .item-2 {
          justify-self: center;
        }
      }

      .item-text-block-1 {
        text-align: end;
      }

      .item-2 {
        justify-self: end;

        @media screen and (max-width: 768px) {
          justify-self: start;
        }
      }

      .column-tree {
        text-align: right;

        p {
          font-weight: 700;
        }

        span {
          font-weight: 500;
        }
      }
    }

    .details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      &.show {
        margin-bottom: 47px;

        @include mediaMax($sm) {
          margin-bottom: 15px;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      span {
        font-size: 13px;
        color: #000000B2;
        justify-self: end;
        align-self: center;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        svg {
          transition: 500ms ease;
          -webkit-transition: 500ms ease;
          -moz-transition: 500ms ease;
          -ms-transition: 500ms ease;
          -o-transition: 500ms ease;

          &.close {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
          }

          &.show {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
          }
        }
      }
    }

    .flite-time {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 4px;

      .flite-line {
        .text-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          color: #797C9A;
          margin-bottom: 4px;
          border-radius: 20px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;

          p {
            font-size: 12px;
            margin: 0;
          }
        }
      }

      .line {
        height: 4px;
        background-color: $primary-color;
        width: 100%;
      }
    }

    .replanting {
      background-color: #fff;
      padding: 6px 12px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      margin-bottom: 60px;

      p {
        font-weight: 600;
      }
    }
  }

  .relus-content {
    line-height: 1.5;

    i {
      // display: block;
      font-size: 14px;
      line-height: 1.5;
    }

    b {
      display: block;
      margin: 16px 0;
      line-height: 1.5;
    }
  }
}