@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.forget-password-wrapper {
  flex-direction: column;
  padding: 40px 30px;
  display: grid;
  gap: 26px;
  color: $primary-color;

  @include mediaMax ($sm) {
    padding: 25px 0 10px;
  }

  & .arrow-left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0;
    border: none;
    background: transparent;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;

    &>img {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      max-width: 100%;
      width: 30px;
    }
  }

  & .forget-password-text {
    color: #919191;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  & input {
    padding: 16px;
    border: 1px solid #babdcc;
    background: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 16px;
  }

  &>img {
    margin: 0 auto;
  }

  & .submit {
    margin: 0 auto;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    width: 60%;

    & .loading-block {
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
    }
  }
}