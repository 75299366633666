@import "../../../styles/variables.scss";

.header-nav-settings-menu {
  position: relative;

  .setting-icon {
    width: 25px;
    height: 25px;


    &.light-theme {
      path {
        fill: $primary-color;
      }
    }

    &.dark-theme {
      path {
        fill: $bg-header-light;
      }
    }
  }

  .planet {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    display: flex;

  }

  img {
    width: 18px;
    height: 18px;
  }

  @media screen and (max-width: 600px) {
    position: static;
  }

  &-button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .header-nav-settings-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;


    .header-nav-setting-group {
      display: flex;
      flex-direction: column;
      gap: 10px;

    }

    p {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin: 0;

      &.light-theme {
        color: #000000;
      }

      &.dark-theme {
        color: #ffffff;
      }
    }

    .nav-modal-header-text {
      // color: #858181;
      font-size: 14px;
      font-weight: 300;

      &.light-theme {
        color: #858181;
      }

      &.dark-theme {
        color: #d6d6d6;
      }
    }

    .settings-modal-block {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      p {
        flex-grow: 1;

      }

      span {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;

        &.light-theme {
          color: #000000;
        }

        &.dark-theme {
          color: #ffffff;
        }
      }
    }

    .checkbox {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background-color: #D9D9D9;

      &.active {
        background-color: $primary-color;
      }
    }

    input {
      align-self: center;
      appearance: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background-color: #D9D9D9;

      &:checked {
        background-color: $primary-color;
        border-color: #fff;
      }
    }
  }
}