@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYREGULAR.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYBLACKITALIC.OTF') format('OTF');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYBOLD.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYBLACKITALIC.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYBOLD.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYHEAVYITALIC.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYLIGHTITALIC.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYMEDIUM.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYTHINITALIC.OTF') format('OTF'),
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('OTF');
}
