@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.modal-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  &.active {
    display: block;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  overflow-y: auto;
  padding: 0 30px;

  &.active {
    animation-name: fadeIn;
  }

  @include mediaMax($sm) {
    padding: 0 15px;
  }
}

.modal-block {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 20px;
  margin: 30px auto;
  max-height: calc(100vh - 60px);
    @include mediaMax($sm)
{
    margin: 15px auto;
  }
}

.modal-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.modal-body {
  overflow: auto;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 20;


  &.button-dark-theme {
      svg {
        path {
          fill: $primary-color-dark;
        }
      }
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: transparent !important;

    path {
      stroke: transparent !important;
    }
  }
}
