@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.payment-method-wrapper {
  width: 1100px;
  margin: auto;
  padding-top: 80px;

  .container {
    gap: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 80px 0px;
    padding: 0px;

    @include mediaMax($md) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include mediaMax($lg) {
    width: auto;
  }

  .payment-method-title {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 34px;
      font-size: 40px;
      font-weight: 700;

      &.dark-theme {
        color: white;
      }

      @include mediaMax($sm) {
        font-size: 30px;
      }
    }

    p {
      font-size: 24px;
      font-weight: 500;

      &.dark-theme {
        color: white;
      }
    }
  }

  .payment-method-bank {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .reload-balance-method-block {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      border-radius: 7px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      -ms-border-radius: 7px;
      -o-border-radius: 7px;
      background: $primary-background;
      border: 1px solid $primary-background;
      box-shadow: 0 2px 4px 0 rgba(23, 25, 35, 0.25);
      //padding: 19px 30px;
      height: 100%;
            &.dark-theme
{
        border: 1px solid black;
      }

      img:nth-child(1) {
        width: 100%;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
      }

      input {
        position: relative;
        align-self: center;
        appearance: none;
        width: 24px;
        height: 23px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border: 1px solid #cbcbcb;
        transition: border 0.3s ease-in-out;
        -webkit-transition: border 0.3s ease-in-out;
        -moz-transition: border 0.3s ease-in-out;
        -ms-transition: border 0.3s ease-in-out;
        -o-transition: border 0.3s ease-in-out;
        &:focus
{
          outline: none;
          border-color: #004aad;

          &::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #004aad;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);

          }
        }
      }
    }
  }

  .payment-method-sum {
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: space-evenly;

    &.dark-theme {
      background-color: $bg-header-dark;
    }

    .InputUIButtonUI {
      display: flex;
      align-items: center;
      color: #fff;

      @include mediaMax($sm) {
        gap: 20px;
      }

      @include mediaMax($md) {
        gap: 20px;
      }

      input {
        &.dark-theme {
          color: white;
        }

        :placeholder.dark-theme {
          color: $text-color-night;
        }
      }
    }

    p {
      color: #e22b2c;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 10px;

      &.dark-theme {
        color: white;
      }
    }

    input {
      width: 97%;
      height: 48px;
      flex-shrink: 0;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      border: 1px solid #d8dadd;
      padding: 10px;

      @include mediaMax($lg) {
        width: 95%;
      }

      @include mediaMax($md) {
        width: auto;
      }

      &::placeholder {
        color: #868686;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  .payment-method-button {
    text-align: center;
    height: 50px;
    border: none;
    color: var(--Base-White, $primary-background);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    transition: all transform 0.2s ease;
    -webkit-transition: all transform 0.2s ease;
    -moz-transition: all transform 0.2s ease;
    -ms-transition: all transform 0.2s ease;
    -o-transition: all transform 0.2s ease;

    &:active {
      transform: scale(0.98);
      // Уменьшаем размер при активации кнопки    :;
      -webkit-transform: scale(0.98);
      -moz-transform: scale(0.98);
      -ms-transform: scale(0.98);
      -o-transform: scale(0.98);
    }
  }
}