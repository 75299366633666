@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

@mixin themeColorHandle($text-color) {
  .if-have-account {
    :nth-child(2) {
      color: $text-color;
    }
  }
}

.registration-page {
  margin: 80px auto;

  &.dark-theme-registration-page {
    @include themeColorHandle($primary-color-dark);
  }

  &.light-theme-registration-page {
    @include themeColorHandle($primary-color);
  }

  .to-login {
    display: none;
  }

  .if-have-account {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    :nth-child(2) {
      font-weight: 600;
      cursor: pointer;
    }
  }
}