@import "../../../styles/variables.scss";

.arrival-time-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .route-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
  
    .route-cities {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
