@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.login-wrapper {
  flex-direction: column;
  padding: 40px 30px;
  display: grid;
  gap: 26px;

  @include mediaMax($sm) {
    padding: 25px 0 10px;
  }

  .eye-off {
    width: 30px;
  }

  .eye-on {
    width: 30px;
    opacity: 0.6;
  }

  .eyes-input-password {
    display: flex;
    position: relative;
  }

  .eyes-on-off {
    position: absolute;
    top: 28px;
    right: 8px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .login-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    border: 1px solid $primary-color;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 40px;

    P {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
    }

    & :nth-child(1) {
      border-right: 1px solid $primary-color;
    }

    & :nth-child(3) {
      border-left: 1px solid $primary-color;
    }
  }

  input {
    padding: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 16px;
  }

  .enter {
    margin: 0 auto;
    border: transparent;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    width: 60%;
  }

  .another-actions {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px;
      cursor: pointer;
    }
  }

  small {
    font-style: 12px;
  }

  .social-icons {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;

    .icon {
      background-color: $primary-color;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }

    svg {
      color: #fff;
      font-size: 25px;
    }
  }

  .incorrect-user-data {
    color: #e53935;
    font-size: 14px;
    margin-top: 4px;
  }
}