@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.booking-order-description-wrapper {
  grid-column: 2/3;
  grid-row: 1/2;

  @include mediaMax($lg) {
    order: 1;
  }

  .order-description {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    padding: 38px 18px;
    display: grid;
    grid-auto-rows: min-content;
    gap: 16px;
    grid-column: 2/3;
    grid-row: 1/-1;
    width: 100%;
    height: fit-content;
    top: 130px;
    position: sticky;

    @mediascreenand(max-width: 1024px) {
      grid-column: 1/1;
      position: static;
      grid-row: 4/5;
    }

    div {
      display: flex;
      justify-content: space-between;
    }

    .item-1 {
      margin-bottom: 16px;
    }

    .item-3 {
      margin-bottom: 16px;
    }

    h3 {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
    }

    .cashback {
      color: #F7B407;
    }

    button {
      padding: 9px 88.94px 9px 89.06px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      background: $primary-color;
      color: $primary-background;
      border: none;
      align-self: self-end;

    }
  }
}