@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.button-ui {
  position: relative;
  background-color: $primary-color;
  color: #fff;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: none;
  cursor: pointer;

  &.dark-theme-button {
    background-color: #BFA130;
    color: #000;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba($color: $primary-color, $alpha: .4);
  }

  & .loading-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  @include mediaMax($sm) {
    width: 100%;
    height: auto;
    font-size: 18px;
  }
}