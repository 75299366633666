@import "../../styles/variables.scss";

.faq-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .faq-header {
    display: flex;
    align-items: center;
    position: relative;

    h1 {
      &.dark-theme {
        color: white;
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    img {
      position: relative;
      left: 110px;
      top: 30px;

      @media screen and (max-width: 768px) {
        left: 0;
      }
    }
  }

  .faq-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .statement {
    display: flex;
    gap: 30px;

    :hover {
      color: $primary-color;
    }

    :hover.dark-theme {
      color: white;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    button {
      border: none;
      background: none;
      color: #728095;
      cursor: pointer;

      :active {
        color: $primary-color;
      }
    }
  }

  .arrow-input-container {
    position: relative;
    margin-bottom: 32px;
    margin-right: 32px;

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }

    .input {
      width: 523px;
      min-height: 50px;
      padding: 24px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border: 1px solid var(--grey-40, #ccd5df);

      &.dark-theme {
        border: 1px solid var(--grey-40, #ffffff);
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      p {
        color: var(--grey-80, #2e3747);
        font-family: SF Pro Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        &.dark-theme {
          color: white;
        }
      }

      .info {
        margin-top: 30px;
      }
    }

    .arrow {
      position: absolute;
      top: 30px;
      right: 10px;
      cursor: pointer;

      svg {
        transition: 300ms ease;
        -webkit-transition: 300ms ease;
        -moz-transition: 300ms ease;
        -ms-transition: 300ms ease;
        -o-transition: 300ms ease;
      }

      .arrow-details {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);

        path {
          stroke: #728095;
        }

        &.dark-theme {
          path {
            stroke: #ffffff;
          }
        }


        &.show-details {
          transform: rotate(270deg);
          -webkit-transform: rotate(270deg);
          -moz-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          -o-transform: rotate(270deg);
        }
      }
    }
  }
}