@import "../../../styles/variables.scss";

.filters-range-slider-wrapper {
  padding: 0 8px 5px;

  .text {
    margin: 10px -8px;
    font-size: 13px;
    line-height: 20px;
    color: #2C3959;
    // color: text-color-night;
    &.dark-theme{
      color: #ffffff;
    }
  }
}
