@import "../../styles/variables.scss";

.booking-page-wrapper {
  .loading-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
  }

  .booking-is-failed {
    width: 100%
  }

  .booking-failed-title {
    color: $primary-color;
    margin-bottom: 16px;
    text-align: center;
  }

  .booking-failed-text {
    line-height: 1.5;
    text-align: center
  }
};
