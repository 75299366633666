@import "../../../styles/variables.scss";

@mixin themeColorHandle($active-color, $login-color) {
  .user-navigations {

    :hover {
      color: $active-color;
    }

    .active {
      color: $active-color;
    }
  }

  .user {
    color: $active-color;
  }

  .header-nav-user-menu-button {
    color: $login-color;

    .user-icon {
      path {
        fill: $login-color;
      }
    }
  }
}

.header-nav-user-menu {
  position: relative;

  &.dark-theme-header-nav-user {
    @include themeColorHandle($primary-color-dark, white)
  }

  &.light-theme-header-nav-user {
    @include themeColorHandle($primary-color, $primary-color)
  }

  @media screen and (max-width: 600px) {
    position: static;
  }

  .user {
    // color: $primary-color;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &.light-theme {
      color: $primary-color;
    }

    &.dark-theme {
      color: $primary-background;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border: 1px solid;
      
}

    @media screen and (max-width: 1150px) {
      span {
        display: none;
      }
    }
  }

  .user-dropdown {
    padding: 16px;
    position: relative;

    .light-theme {
      color: black;
    }

    .dark-theme {
      color: $primary-background;
    }

    .log-out {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 15px;

      span {
        margin: 0;
        color: $error-color;
        cursor: pointer;

        :hover {
          opacity: 0.8;
        }
      }
    }

    h5,
    small {
      display: block;
      margin-bottom: 14px;
    }

    .user-title {
      font-size: 20px;
      font-weight: 700;
      position: relative;
    }

    .email {
      font-size: 16px;
    }

    .user-id {
      text-transform: uppercase;
    }

    .user-navigations {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 20px;

      .link {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        text-decoration: none;

        span {
          margin: 0;
        }
      }
    }
  }

  .img-block {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 6px;
    
}
}

.header-nav-user-menu-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent !important;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;


.user-icon{
  &.dark-theme{
    path{
      fill: white;
    }
  }
}

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.header-nav-user-menu-dropdown {
  left: -15px !important;

  &>div {
    padding: 20px 30px !important;
  }
}