@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes animateNext {
  0% {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
  }

  to {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

@keyframes animatePrev {
  0% {
    transform: translateX(-10px);
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
  }

  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.calendar-one-way-button {
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;

}

.calendar-one-way-line {
  height: 1px;
  width: 100%;
  background-color: #ddd;
  margin-bottom: 10px;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  &.centered {
    justify-content: center;
  }

  & .text {
    font-size: 16px;
  }
}

.calendar-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .calendar-months {
    position: relative;
    width: 680px;
    padding: 10px 0;

    @include mediaMax($md) {
      width: 310px;
    }

    .calendar-months-block {
      display: grid;
      grid-template-columns: repeat(2, 50%);

      @include mediaMax($md) {
        grid-template-columns: 1fr;
      }

      &.animate-next {
        animation: animateNext 0.3s forwards, fadeIn 0.2s forwards;
        -webkit-animation: animateNext 0.3s forwards, fadeIn 0.2s forwards;
      }

      &.animate-prev {
        animation: animatePrev 0.3s forwards, fadeIn 0.2s forwards;
        -webkit-animation: animatePrev 0.3s forwards, fadeIn 0.2s forwards;
      }

      .calendar-month-block {
        display: flex;
        flex-direction: column;
        padding: 0 7.5px;

        @include mediaMax($md) {
          padding: 0;
        }

        .calendar-month-title {
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          padding: 6px 0;
          margin-bottom: 10px;

          @include mediaMax($md) {
            margin-bottom: 20px;
          }
        }

        .calendar-month-weeks-block {
          display: grid;
          grid-template-columns: repeat(7, 38px);
          gap: 7px;
          padding-bottom: 10px;
          text-transform: uppercase;

          .day {
            text-align: center;
            font-size: 15px;
            font-weight: 300;
          }
        }

        .calendar-month-days {
          display: grid;
          grid-template-columns: repeat(7, 38px);
          gap: 7px;

          .calendar-day-button {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            width: 40px;
            height: 40px;
            font-size: 14px;
            background-color: transparent;
            border: none;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;

            &:hover {
              cursor: pointer;
            }

            &.selected {
              background-color: #004AAD;
              color: #fff;
            }

            &.active {
              background-color: #004AAD;
              color: #fff;
            }

            &.disabled {
              color: #9e9e9e;
              cursor: default;
            }
          }
        }
      }
    }

    .months-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 10px;
      z-index: 50;
      width: 32px;
      height: 32px;
      padding: 0;
      border: none;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      cursor: pointer;

      .left-arrow {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
      }

      &:disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      }

      &.next {
        left: auto;
        right: 10px;
      }

      @include mediaMax($md) {
        left: 0;

        &.next {
          right: 0;
        }
      }
    }
  }
}