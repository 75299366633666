@import "../../styles/mixins.scss";

.orders-filters-wrapper {
    position: relative;

    @media screen and (max-width: 768px) {
        grid-column: 1/3;
    }

    .orders-filter-block {
        position: sticky;
        top: 130px;
        margin: 80px 0;
        padding: 16px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        @include mediaMax($md) {
            margin: 0;
        }


        .send-button {
            width: 100%;
        }
    }

    .filters-input {
        margin-bottom: 16px;
        display: grid;
        gap: 20px;
    }
}