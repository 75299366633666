@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.edit-user-modal {
  .edit-user-inputs {
    display: grid;
    gap: 16px;
    padding-top: 24px;
  }

  input {
    padding: 5px 10px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-color: rgb(0 0 0 / 26%);
    border-width: 0;
    border-bottom-width: 1px;
    outline: none;
    font-size: 14px;
    width: 100%;
  }

  .inputUpper {
    text-transform: uppercase;
  }

  .error-message {
    color: $error-color;
    font-size: 12px;
    display: block;
    margin-top: 5px;
  }
}