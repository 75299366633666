@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.corporate-table-wrapper {
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  box-shadow: none;

  @include mediaMax($lg) {
    overflow-x: scroll;
  }

  @include mediaMax($md) {
    overflow-x: scroll;
  }

  .corporate-transactions-title {
    display: flex;

    th {
      background-color: $primary-color;
      color: #fff;
      min-width: 150px;
      padding: 8px;
    }

    th:nth-child(-n+4) {
      min-width: 230px;
    }
  }

  .corporate-transactions-title th:last-child {
    min-width: 400px;
  }

  .corporate-transactions-info {
    .dropdown-block {
      background-color: $error-color;
      height: 400px;
    }

    .th-text {
      &.dark-theme {
        color: $primary-background;
      }
    }

    button {
      background: inherit;
      border: none;
      color: $primary-text-color;
      font-weight: 600;
      font-size: 16.5px;
      cursor: pointer;


      &:hover {
        color: $primary-color;
      }

      &.dark-theme {
        color: $primary-background;
      }
    }

    .positive-value {
      color: $error-color;
    }

    .negative-value {
      color: $success-color;
    }

    th:nth-child(2) {
      font-weight: 400;
    }

    th:nth-child(3) {
      font-weight: 100;
    }

    th:nth-child(4) {
      font-weight: 100;
    }

    th:nth-child(5) {
      font-weight: 100;
    }

    th:nth-child(-n+4) {
      min-width: 230px;
    }

    th:last-child {
      min-width: 400px;
      text-align: left;
    }
  }

  table {
    border-collapse: collapse;
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    letter-spacing: 0.4px;
    max-width: 400px;
  }

  .modal-body {

    .line {
      margin: 30px 0;
    }

    .passenger-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;


      .indicators {
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;

      }

      .data {
        flex-grow: 1;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px
      }

      p {
        margin-bottom: 10px;
      }
    }

    display: flex;
    justify-content: center;
    padding: 20px;

    .order-id-details {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .price {
        color: $primary-color;
      }

      h2 {
        align-self: center;
        margin-bottom: 10px;
      }

      p {
        align-self: center;
      }

      .details {
        color: $primary-color;
        margin-bottom: 30px;
        text-align: center;
      }

      .segment-info {
        display: flex;
        gap: 20px;

        @include mediaMax($sm) {
          flex-direction: column;
          gap: 10px;
        }

        .flight {
          display: flex;
          gap: 10px;

          @include mediaMax($sm) {
            width: 100%;
          }

          .from,
          .to {
            width: 120px;

            .time {
              font-size: 24px;
              line-height: 36px;
            }

            .small-text {
              font-size: 11px;
              line-height: 18px;
              color: #797C9A;
            }

            @include mediaMax($md) {
              width: 90px;
            }
          }

          .from {
            text-align: right;
          }

          .supplier-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            gap: 5px;

            .small-text {
              display: none;
            }

            @include mediaMax($sm) {
              .small-text {
                display: block;
                font-size: 11px;
                line-height: 18px;
                color: #797C9A;
                text-align: center;
              }
            }

            .supplier {
              display: flex;
              align-items: center;
              gap: 4px;
              font-size: 12px;
              line-height: 18px;
              color: #797C9A;

              @include mediaMax($sm) {
                img {
                  display: none;
                }
              }
            }

            .fly-line {
              width: clamp(100px, 32vw, 450px);
              height: 4px;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              background-color: $primary-color;

              @include mediaMax($xl) {
                width: 300px;
              }

              @include mediaMax($md) {
                width: 150px;
              }

              @include mediaMax($sm) {
                width: 100%;
              }
            }
          }
        }

        .duration-and-flight {
          @include mediaMax($sm) {
            display: none;
          }

          .time {
            color: $secondary-text-color;
            font-size: 24px;
            line-height: 36px;
          }

          .small-text {
            font-size: 13px;
            line-height: 18px;
            color: #797C9A;
          }
        }
      }
    }
  }
}