@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.order-page-wrapper {
  .loading-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
  }
}
