@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.avia-price-wrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 130px;
  width: 100%;
  padding: 30px;
  box-shadow: 0px 2px 4px 0px rgba(23, 25, 35, 0.25);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;

  @include mediaMax($lg) {
    position: relative;
    top: 0;
  }

  @include mediaMax($md) {
    padding: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  & .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15px;

    &:nth-of-type(1) {
      margin-top: 0;
    }

    & .label {
      font-weight: 600;
      line-height: 28px;
    }

    & .value {
      font-weight: 700;
      line-height: 28px;
    }

    &.sub {
      padding-left: 10px;
      margin-top: 0;

      & .label {
        font-weight: 400;
        font-size: 14px;
      }

      & .value {
        font-weight: 400;
        font-size: 14px;
      }
    }

    &.big {
      font-size: 24px;
      margin-top: 20px;

      &:nth-of-type(1) {
        margin-top: 40px;
      }

      & .label {
        font-weight: 700;
      }

      & .value {

        &.cashback {
          color: #F7B407;
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    @include mediaMax($lg) {
      flex-direction: row;
    }

    @include mediaMax($sm) {
      flex-direction: column;
    }

    ._buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      background-color: $success-color;
      letter-spacing: 0.5px;

    }

    .cansel-button {
      background-color: #ff6c6c;
    }
  }
}

.avia-payment-methods-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .payment {
    width: 250px;
  }

  & .avia-payment-title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: #2C3959;
  }

  & .methods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include mediaMax($sm) {
      grid-template-columns: 1fr;
    }

    & .method {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 25px 30px;
      height: 80px;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      box-shadow: 0px 2px 4px 0px rgba(23, 25, 35, 0.25);
      cursor: pointer;
      user-select: none;

      @include mediaMax($sm) {
        padding: 15px;
      }

      & .icon {
        height: 100%;

        @include mediaMax($sm) {
          height: auto;
        }

        &>img {
          max-height: 100%;
          height: 100%;

          @include mediaMax($sm) {
            max-height: 30px;
          }
        }
      }

      & .labels {
        flex-grow: 1;

        & .label {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;

          @include mediaMax($sm) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        & .not-enough-balance {
          font-size: 14px;
          line-height: 18px;
          color: #B70000;

          @include mediaMax($sm) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  & .payment-button {
    border: none;
    background-color: $primary-color;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-top: 15px;
    padding: 18px 24px;
    text-decoration: none;
    cursor: pointer;
    position: relative;

    &:disabled {
      background-color: rgba(0, 74, 173, .4);
    }

    .payment-button-loading-block {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-color;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    @include mediaMax($sm) {
      font-size: 16px;
      padding: 15px 20px;
    }
  }
}