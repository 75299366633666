@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.check-email-wrapper {
  flex-direction: column;
  padding: 40px 30px;
  display: grid;
  gap: 26px;
  color: $primary-color;

  @include mediaMax($sm) {
    padding: 25px 0 10px;
  }

  & .check-email-text {
    color: #919191;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  &>img {
    margin: 0 auto;
  }

  .submit {
    margin: 30px auto 0;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    width: 60%;
  }
}