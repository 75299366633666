@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
@import "../styles.scss";

.passengers-sidebar-wrapper {
  position: relative;
  height: 100%;

  @include mediaMax($md) {
    grid-row: 1/2;
    margin-bottom: 24px;
  }

  .passengers-sidebar {
    position: sticky;
    padding: $indent;
    max-height: 60vh;
    top: 150px;
    border-radius: $indent;
    -webkit-border-radius: $indent;
    -moz-border-radius: $indent;
    -ms-border-radius: $indent;
    -o-border-radius: $indent;
    display: grid;
    overflow: auto;

    @include mediaMax($md) {
      max-height: 100%;
    }

    .add-user {
      img {
        max-width: 100%;
      }
    }

    label {
      margin-top: 10px;
    }

    input {
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      font-size: 14px;
      padding: 0;
      border: 0;
      border-bottom: 1px solid #D0D1D7;
      color: #353535;
    }

    .label {
      font-size: 14px !important;
    }

    h4 {
      margin-bottom: 20px;
    }

    button {
      padding: 5px 16px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      margin-top: 10px;

      @include mediaMax($sm) {
        font-size: 13px;
      }
    }
  }
}