@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.success-ticket-purchase-wrapper {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;

  .success-ticket-image-text {
    display: flex;

    @include mediaMax ($sm) {
      display: flex;
      flex-direction: column;
    }

    @include mediaMax ($md) {
      display: flex;
      flex-direction: column;
    }
  }

  .success-ticket-image {

    @include mediaMax ($sm) {
      align-self: center;
    }

    @include mediaMax ($md) {
      align-self: center;
    }
  }

  .success-ticket-title-text {
    align-self: center;

    .success-ticket-title {
      margin-bottom: 45px;

      @include mediaMax($sm) {
        text-align: center;
      }

      @include mediaMax($md) {
        text-align: center;
      }

      p {
        color: #000;
        font-size: 48px;
        font-weight: 600;
        line-height: 44.5px;

        @include mediaMax ($sm) {
          font-size: 26px;
        }

        @include mediaMax ($md) {
          font-size: 32px;
        }

        @include mediaMax ($lg) {
          font-size: 40px;
        }
      }
    }

    .success-ticket-text {

      p:first-child {
        margin-bottom: 5px;
        color: #00B712;
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;

        @include mediaMax($sm) {
          text-align: center;
        }

        @include mediaMax($md) {
          text-align: center;
        }

        img {
          margin-right: 5px;
        }
      }

      p:nth-child(2) {
        color: #F1B101;
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;

        @include mediaMax($sm) {
          text-align: center;
        }

        @include mediaMax($md) {
          text-align: center;
        }

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .success-ticket-button {
    margin-top: 50px;

    .success-ticket-link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin: 0 auto;
      width: 316px;
      height: 54px;
      border-radius: 9px;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      -ms-border-radius: 9px;
      -o-border-radius: 9px;
      background: #004AAD;
      color: #FFF;
      font-size: 15px;
      line-height: 28px;
      border: none;
    }
  }
}