@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@mixin themeColorHandle($input-bg, $text-color) {
  background-color: $input-bg;

  .cabin {
    color: $text-color;
  }

  .passengers-count {
    color: $text-color;
  }
}

.avia-search-class-passengers-select-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 52px;
  background-color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
  border: 1px solid rgb(0 0 0 / 35%);

  &.dark-theme-class-passengers {
    @include themeColorHandle($input-background-dark, $text-color-night);
  }

  &.light-theme-class-passengers {
    @include themeColorHandle(#ffffff, $text-color-day);
  }

  &.advanced {
    width: 100%;
  }

  .cabin {
    padding: 4px 15px 0;
    font-size: 12px;
    color: #797C9A;
  }

  .passengers-count {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-top: 2px;
  }

  .dropdown-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    position: absolute;
    right: 5px;
    top: 18px;
    color: #808080;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);

    .passengers-class-arrow-icon {
      transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      -o-transform: rotate(270deg);

      path {
        stroke: #404B5A;
      }
    }

    &.active {
      transition: all .2s ease;
      -webkit-transition: all .2s ease;
      -moz-transition: all .2s ease;
      -ms-transition: all .2s ease;
      -o-transition: all .2s ease;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }

  .passengers {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .passenger {
      display: flex;
      align-items: center;

      .title-and-description {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .title {
          font-size: 12px;
          color: #000;

          &.dark-theme {
            color: $text-color-night;
          }
        }

        .description {
          font-size: 10px;
          color: #999;
        }
      }

      .counter {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #333;

        span {
          font-size: 18px;
          width: 40px;
          text-align: center;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border: none;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          cursor: pointer;

          img {
            width: 11px;
          }

          &.disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }

  .cabins {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding-top: 15px;

    button {
      height: 32px;
      padding: 0;
      background-color: transparent;
      color: #999;
      border: 1px solid #ccc;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      cursor: pointer;

      &.active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;

        &.button-dark-theme {
          background-color: $primary-color-dark;
          border-color: $primary-color-dark;
          color: #000;
        }
      }

      &.full {
        grid-column: 1/3;
      }
    }
  }
}