@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.orders {
    padding-top: 100px;

    .loading-dots {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h1 {
        text-align: center;
        padding: clamp(16px, 3vw, 30px) 0;
    }

    .month-title {
        font-size: 24px;
        margin-bottom: 16px;
        padding-top: 16px;

        &.dark-theme {
            color: $text-color-night;
        }

        &::first-letter {
            text-transform: uppercase;
        }

        @media screen and (max-width: 576px) {
            text-align: center;
        }
    }

    .orders-wraper {
        display: grid;
        grid-template-columns: 1fr;
        gap: clamp(16px, 3vw, 32px);
        position: relative;

        @include mediaMax($xl) {
            grid-template-columns: 1fr;
        }

        @include mediaMax($lg) {
            grid-template-columns: 1fr;
        }

        .download-document {
            display: flex;
            justify-content: center;
            gap: 10px;
            font-size: clamp(14px, 2vw, 18px);
            color: $primary-color;
            cursor: pointer;
        }

        .filter-block {
            grid-column: 2/3;
        }

        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 640px) {
            grid-template-columns: 1fr;
        }

        .profile-block {
            position: sticky;
            top: 0;
            height: fit-content;
            padding-top: 16px;

            @media screen and (max-width: 768px) {
                grid-column: 1/2;
                grid-row: 1/2;
                position: static;
            }

            @media screen and (max-width: 640px) {
                grid-row: 1/2;
            }

        }

        .orders-block-item {
            @media screen and (max-width: 768px) {
                grid-column: 1/3;
            }

            @media screen and (max-width: 640px) {
                grid-column: 1/2;
            }
        }

        .orders-block {
            line-height: 1.6;
            font-size: 16px;
            padding: 30px;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            margin-bottom: 16px;

            &.dark-theme {
                background-color: $bode-of-passenger-list-card;
            }

            .order-item-1 {
                display: flex;
                gap: 50px;
                margin-bottom: 20px;
                justify-content: space-between;

                p {
                    &.dark-theme {
                        color: $text-color-night;
                    }
                }

                @media screen and (max-width: 576px) {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }

                .order-article {
                    font-weight: 600;
                    font-size: 18px;

                    &.dark-theme {
                        color: $text-color-night;
                    }
                }

                .from-to {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-weight: 600;
                    font-size: 18px;

                    &.dark-theme {
                        color: $text-color-night;
                    }

                    .from-to-arrow {
                        width: 24px;

                        path {
                            stroke: $secondary-text-color;
                        }

                        &.dark-theme {
                            path {
                                stroke: $text-color-night;
                            }
                        }
                    }
                }
            }

            .order-item-2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;

                @media screen and (max-width: 576px) {
                    flex-direction: column;
                    align-items: stretch;
                }

                .actions-message {
                    display: flex;
                    gap: 6px;
                    color: #F1B101;
                    align-items: center;

                    @media screen and (max-width: 576px) {
                        justify-content: center;
                        align-items: center;
                    }
                }

                button {
                    min-width: 133px;
                    font-size: 18px;
                    font-weight: 600;
                    padding: 8px 16px;
                    border: none;
                }
            }

            .status-and-link-block {
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    text-decoration: none;
                    color: $primary-color;
                    font-weight: 500;
                    font-size: clamp(14px, 2vw, 18px);

                    .primary-arrow {
                        width: 30px;
                        height: auto;
                    }
                }

                @include mediaMax($sm) {
                    flex-direction: column;

                    a {
                        img {
                            width: 22px;
                        }
                    }
                }
            }
        }

        .order-info {

            margin-top: 16px;

            @media screen and (max-width: 1024px) {
                grid-column: 1/-1;
                grid-row: 1/2;
            }

            @media screen and (max-width: 768px) {
                grid-column: 2/3;
                grid-row: 1/2;
            }

            @media screen and (max-width: 640px) {
                grid-column: 1/2;
                grid-row: 2/3;
            }
        }
    }
}