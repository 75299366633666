@import "../../../styles/variables.scss";

.not-found-recommendations-wrapper {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 50px 15px;
  max-width: 500px;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;

  .margin {
    position: relative;

    .first {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: 28px;
    }

    .second {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}