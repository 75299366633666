footer {
  background-color: #000;
  color: #fff;

  & .line {
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
