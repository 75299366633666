@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header-logo-wrapper {
  display: flex;
  align-items: center;
  gap: clamp(10px, 4vw, 30px);
  height: 100%;

  .header-logo-iata {
    background: inherit;
    border: none;

    @include mediaMax($sm) {
      display: none;
    }
  }

  .dropdown-block {
    min-height: auto;
    width: 100%;
    padding: 20px;

    @include mediaMax($md) {
      width: 100%;
      margin-right: 0;
    }

    @include mediaMax($sm) {
      width: 100%;
      margin-right: 0;
    }

    p {
      font-size: 17px;
      color: $secondary-text-color;
    }
  }

  .header-logo-business {
    border: none;
    background: inherit;

    @include mediaMax($sm) {
      display: none;
    }

    p {
      text-align: start;
      text-transform: uppercase;
      font-size: clamp(8px, 2vw, 20px);
      font-weight: 500;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;

      &.light-theme {
        color: #004aadd4;
        text-shadow: 1px 1px 1px #beafe9;
      }

      &.dark-theme {
        color: #ffffffd4;
        text-shadow: 1px 1px 1px #beafe9;
      }

      &:hover {
        text-shadow: 1px 2px 1px #ac9bdb70;
      }
    }
  }

  .header-logo-image {
    max-height: 100%;
    width: clamp(60px, 7vw, 90px);
    cursor: pointer;
  }

  .header-logo-vertical-line {
    width: 0.5px;
    background: $small-tag-color;
    height: 60%;

    @include mediaMax($sm) {
      display: none;
    }
  }

  .for-dropdown-text {
    &.light-theme {
      color: $text-color-day;
    }

    &.dark-theme {
      color: $text-color-night;
    }
  }
}