@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.avia-time-limit-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border: 1px solid #ff0000;
  box-shadow: 0px 4px 10px 0px rgba(98, 112, 139, 0.10);
  padding: 60px 100px;
  overflow: hidden;
  position: relative;

  &.avia-time-limit-wrapper-dark-theme {
    background-color: $secondary-bg-dark;
  }

  &avia-time-limit-wrapper-light-theme {
    background-color: transparent;
  }

  @include mediaMax($xl) {
    padding: 50px;
  }

  @include mediaMax($md) {
    padding: 30px;
  }

  @include mediaMax($sm) {
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    gap: 20px;

  }

  @include mediaMax($sm) {
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  & .payment-attention-image {
    position: absolute;
    left: -30px;
    bottom: -37px;
    width: 130px;
    height: 130px;
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);

    @include mediaMax($xl) {
      left: -25px;
      bottom: -25px;
      width: 100px;
      height: 100px;
    }

    @include mediaMax($md) {
      display: none;
    }
  }

  & .time-limit-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;

    @include mediaMax($md) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @include mediaMax($xl) {
      gap: 30px;
    }

    @include mediaMax($md) {
      gap: 20px;
    }

    & .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & .value {
        font-size: 36px;
        font-weight: 600;

        @include mediaMax($md) {
          font-size: 24px;
        }
      }

      & .label {
        font-size: 12px;
        line-height: 28px;
        color: rgba($color: #000000, $alpha: .5);
      }
    }

    & .dots {
      font-size: 20px;
      line-height: 28px;
      color: rgba($color: #000000, $alpha: .5);
    }
  }

  & .time-is-expired {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #B70000;
  }
}