@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

$shadow-color: 0px 0px 4px 0px #b5b5b5;
$text-shadow: -1px -1px 0 dimgrey, 1px -1px 0 dimgrey, -1px 1px 0 dimgrey, 1px 1px 0 dimgrey;
$indent: 10px;

@mixin box-shadow {
  -webkit-box-shadow: $shadow-color;
  -moz-box-shadow: $shadow-color;
  box-shadow: $shadow-color;
}

.passengers-list-page {
  margin: 20px auto;

  &.dark-theme {
    color: white;
  }

  .passengers-wrap {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 24px;

    @include mediaMax($xl) {
      grid-template-columns: 4fr 1fr;
    }

    ;

    @include mediaMax($lg) {
      grid-template-columns: 1fr 28%;
    }

    ;

    @include mediaMax($md) {
      grid-template-columns: 1fr;
    }
  }

  .passengers-page-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-bottom: 100px;

    @include mediaMax($lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mediaMax($md) {
      grid-template-columns: 1fr;
    }

    .passenger-item {
      border-radius: $indent;
      -webkit-border-radius: $indent;
      -moz-border-radius: $indent;
      -ms-border-radius: $indent;
      -o-border-radius: $indent;
      display: grid;
      grid-template-rows: 74px repeat(3, 51px) 57px;

      h2 {
        font-size: 20px;
      }

      :nth-child(1) {
        .user-data {
          border: none;
        }
      }

      .adt {
        background-color: #3679D6;
        color: #ffffff;
      }

      .not-adt {
        background-color: #FF8940;
        color: #ffffff;
      }

      h2 {
        padding: $indent;
        color: #454545;
        border-radius: $indent $indent 0 0;
        -webkit-border-radius: $indent $indent 0 0;
        -moz-border-radius: $indent $indent 0 0;
        -ms-border-radius: $indent $indent 0 0;
        -o-border-radius: $indent $indent 0 0;
        line-height: 1.4;
      }

      .user-data {
        padding: 5px $indent;
        justify-content: space-between;
        border-top: 1px dashed;
        font-size: 16px;

        &.dark-border-color {
          border-color: $border-color-dark;
        }

        &.light-border-color {
          border-color: $border-color-light;
        }

        :nth-child(1) {
          color: $small-tag-color;
          font-size: 12px;
        }

        span {
          padding: 2px 0;
          font-size: 16px;
        }
      }

      span {
        display: block;
      }

      .btn-block {
        display: grid;
        grid-template-columns: 1fr 37px;
        gap: $indent;
        padding: $indent;
        align-self: end;

        .user-button {
          background-color: transparent;
          color: $primary-color;
          border: 1px solid $primary-color;

          &.dark-theme {
            color: white;
            border: 1px solid white;
          }

          @include mediaMax($sm) {
            font-size: 15px;
          }
        }

        .is-choose {
          background-color: $primary-color;
          color: #fff;
        }

        .delete-btn {
          background-color: #ff7171;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          border-radius: $indent;
          -webkit-border-radius: $indent;
          -moz-border-radius: $indent;
          -ms-border-radius: $indent;
          -o-border-radius: $indent;
          cursor: pointer;

          .reload {
            animation: rotate 2s linear infinite;
            -webkit-animation: rotate 2s linear infinite;

            @keyframes rotate {
              from {
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
              }

              to {
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                -o-transform: rotate(360deg);
              }
            }
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .modal-ui-del {
    margin-top: 33vh;
  }

  .modal-ui-delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
      font-size: 25px;
      text-align: center;
      font-weight: 500;
      margin-top: 15px;
    }

    .modal-ui-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 10px;

      .btn-yes,
      .btn-no {
        margin-top: 20px;
        width: 150px;
        height: 40px;
        font-size: large;
      }

      .btn-yes:hover {
        background-color: $success-color;
      }

      .btn-no:hover {
        background-color: $error-color;
      }

      @media (max-width: $md) {

        .btn-no,
        .btn-yes {
          width: 400px;
          font-size: larger;
        }
      }
    }

    @media (max-width: $md) {
      .modal-ui-btn {
        flex-direction: column;
        gap: 10px;
        /* Уменьшаем расстояние между элементами */
      }
    }
  }
}