@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../styles.scss";

.gender-checkbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $indent;

  button {
    background-color: transparent;
    color: #62657F;
    border: 1px solid rgba(0, 0, 0, 0.26);
    padding: 5px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

  .is-male {
    background-color: $primary-color;
    color: #ffffff;
  }

  .is-famale {
    background-color: $primary-color;
    color: #ffffff;
  }

  span {
    color: #e53935;
    font-size: 14px;
    margin-top: 4px;
  }
}