@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.booking-contact-info-wrapper {
    grid-column: 1/2;

    .booking-page-bg {
        background-color: $error-color;
        padding: 48px 0;
    }

    .contacts-info-wrapper {
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        padding: 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        grid-column: 1/2;
        position: relative;

        @include mediaMax($sm) {
            padding: 20px 15px;
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            width: 90%;

            @media screen and (max-width: 640px) {
                width: 100%;
            }
        }

        p {
            color: #A0A2AB;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            width: 90%;

            @media screen and (max-width: 640px) {
                width: 100%;
            }
        }

        label {
            color: #62657F;
            font-size: 14px;
            font-weight: 400;
        }

        input {
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            padding: 9.5px 13px 9.5px 13px;
        }
    }

    .contacts-block {
        display: flex;
        gap: 20px;
        width: 90%;

        @media screen and (max-width: 640px) {
            flex-direction: column;
            width: 100%;
        }
    }

    .contact-input {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 100%;

        img {
            position: absolute;
            top: 0;
            right: 0;
            height: clamp(100px, 16vw, 200px);
        }
    }

}