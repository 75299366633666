.result-page-wrapper {
  display: flex;

  .loading-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
  }

  .modal-block {
    align-self: center;
    text-align: center;

    p {
      margin-bottom: 20px;
    }
  }
};