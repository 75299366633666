@import "../../../styles/variables.scss";

.filter-group-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  &.openable {
    margin: 0 -20px;
    padding: 0 20px;
    border-top: 1px solid #ccc;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 0 10px;

    &.has-padding {
      padding-top: 10px;
      cursor: pointer;
    }

    .icon-block {
      display: flex;
      font-size: 20px;
      color: #787878;
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -ms-transition: all .3s ease;
      -o-transition: all .3s ease;
      margin-left: -5px;
    
      &.active {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .body {
    height: 0;
    overflow: hidden;
  
    &.active {
      height: auto;
      overflow: unset;
    }
  
    &.paddingBottom {
      padding-bottom: 10px;
    }
  }
}
