@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.single-news-page-wrapper {
  .news-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    margin: 100px auto;
    align-items: center;
    padding: 100px 24px;
    border-top: 2px solid $primary-color;
    border-bottom: 2px solid $primary-color;

    @include mediaMax($lg) {
      grid-template-columns: 1fr;
    }

    @include mediaMax($sm) {
      gap: 30px;
      padding: 50px 0;
    }

    h2 {
      color: $primary-color;
      margin-bottom: 16px;
      font-size: 32px;

      @include mediaMax($md) {
        font-size: 24px;
      }

      @include mediaMax($sm) {
        font-size: 18px;
      }
    }

    .img-block {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 16px;
      box-shadow: 1px 1px 6px 0px #0000002b;
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      -ms-border-radius: 16px;
      -o-border-radius: 16px;

      img {
        width: 100%;
      }
    }

    .content {
      line-height: 1.5;
      margin-bottom: 16px;
      color: #2f2f2f;
      font-size: 16px;

      @include mediaMax($sm) {
        font-size: 14px;
      }
    }
  }
}