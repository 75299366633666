@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.corporate-table-commission {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  @include mediaMax($lg) {
    overflow-x: auto;
  }

  .corporate-commission-title {
    display: block;

    th {
      padding: 8px;
      min-width: 260px;
      background-color: rgb(56 102 163);
      color: #fff;
      vertical-align: middle;
    }
  }

  .corporate-commission-info {
    button {
      background: inherit;
      border: none;
      // color: $primary-text-color;
      font-weight: 600;
      font-size: 16.5px;
      cursor: pointer;

      &.light-theme {
        color: $primary-text-color;
      }

      &.dark-theme {
        color: $primary-background;
      }
    }

    th {
      padding: 10px;

      &.light-theme {
        color: #000000;
      }

      &.dark-theme {
        color: #fafafa;
      }
    }

    th:nth-child(1) {}

    th:nth-child(2) {
      font-weight: 100;
    }

    th:nth-child(3) {
      font-weight: 100;
    }

    th:nth-child(4) {
      font-weight: 100;
    }

    th:nth-child(5) {
      font-weight: 100;
    }

    th:nth-child(6) {
      font-weight: 100;
    }

    th:nth-child(7) {
      font-weight: 100;
    }

    th:nth-child(8) {
      font-weight: 100;
    }

    th:nth-child(9) {
      color: $success-color;
      font-weight: 100;
    }

    th:nth-child(10) {
      font-weight: 600;
    }

    th:nth-child(11) {
      font-weight: 100;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    min-width: 230px;
    border: 1px solid #ddd;
    text-align: center;
    letter-spacing: 0.4px;
  }

  .modal-body {
    .passenger-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      .indicators {
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }

      .data {
        flex-grow: 1;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
      }

      p {
        margin-bottom: 10px;
      }
    }

    display: flex;
    justify-content: center;
    padding: 20px;

    .order-id-details {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .price {
        color: $primary-color;
      }

      h2 {
        align-self: center;
        margin-bottom: 10px;
      }

      p {
        align-self: center;
      }

      .details {
        color: $primary-color;
        margin-bottom: 30px;
        text-align: center;
      }

      .segment-info {
        display: flex;
        gap: 20px;

        @include mediaMax($sm) {
          flex-direction: column;
          gap: 10px;
        }

        .flight {
          display: flex;
          gap: 10px;

          @include mediaMax($sm) {
            width: 100%;
          }

          .from,
          .to {
            width: 120px;

            .time {
              font-size: 24px;
              line-height: 36px;
            }

            .small-text {
              font-size: 11px;
              line-height: 18px;
              color: #797c9a;
            }

            @include mediaMax($md) {
              width: 90px;
            }
          }

          .from {
            text-align: right;
          }

          .supplier-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            gap: 5px;

            .small-text {
              display: none;
            }

            @include mediaMax($sm) {
              .small-text {
                display: block;
                font-size: 11px;
                line-height: 18px;
                color: #797c9a;
                text-align: center;
              }
            }

            .supplier {
              display: flex;
              align-items: center;
              gap: 4px;
              font-size: 12px;
              line-height: 18px;
              color: #797c9a;

              @include mediaMax($sm) {
                img {
                  display: none;
                }
              }
            }

            .fly-line {
              width: clamp(100px, 32vw, 450px);
              height: 4px;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              background-color: $primary-color;

              @include mediaMax($xl) {
                width: 300px;
              }

              @include mediaMax($md) {
                width: 150px;
              }

              @include mediaMax($sm) {
                width: 100%;
              }
            }
          }
        }

        .duration-and-flight {
          @include mediaMax($sm) {
            display: none;
          }

          .time {
            color: $secondary-text-color;
            font-size: 24px;
            line-height: 36px;
          }

          .small-text {
            font-size: 13px;
            line-height: 18px;
            color: #797c9a;
          }
        }
      }
    }
  }
}