@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.recommendation-modal-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;

  @include mediaMax($sm) {
    padding: 0;
  }

  .tabs {
    display: flex;
    gap: 20px;
    margin-bottom: 5px;

    .tab-button {
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      color: #868686;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      cursor: pointer;

      &.active {
        color: $primary-color;
      }

      &.button-dark-theme {

        &.active {
          color: $primary-color-dark !important;
        }
      }
    }

    .vertical-line {
      width: 0.5px;
      background: #DADADA;
      height: 24px;
    }
  }

  .local-time-info {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 30px;
  }

  .routes {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .route {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-top: 1px solid #F5F5F7;
      padding-top: 30px;

      .route-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .route-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
        }
      }

      .segment {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .transfer {
          padding: 15px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;

          .transfer-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 5px;
          }

          .transfer-text {
            font-size: 14px;
            line-height: 18px;
            color: #62657F;
          }
        }

        .segment-info {
          display: flex;
          gap: 20px;

          @include mediaMax($sm) {
            flex-direction: column;
            gap: 10px;
          }

          .flight {
            display: flex;
            gap: 10px;

            @include mediaMax($sm) {
              width: 100%;
            }

            .from,
            .to {
              width: 150px;

              .time {
                font-size: 24px;
                line-height: 36px;
              }

              .small-text {
                font-size: 11px;
                line-height: 18px;
                color: #797C9A;
              }

              @include mediaMax($lg) {
                width: 120px;
              }

              @include mediaMax($md) {
                width: 90px;
              }
            }

            .from {
              text-align: right;
            }

            .supplier-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;

              .small-text {
                display: none;
              }

              @include mediaMax($sm) {
                flex-grow: 1;

                .small-text {
                  display: block;
                  font-size: 11px;
                  line-height: 18px;
                  color: #797C9A;
                  text-align: center;
                }
              }

              .supplier {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 12px;
                line-height: 18px;
                color: #797C9A;

                @include mediaMax($sm) {
                  img {
                    display: none;
                  }
                }
              }

              .fly-line {
                width: 650px;
                height: 4px;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                background-color: $primary-color;

                @include mediaMax($xl) {
                  width: 450px;
                }

                @include mediaMax($lg) {
                  width: 300px;
                }

                @include mediaMax($md) {
                  width: 150px;
                }

                @include mediaMax($sm) {
                  width: 100%;
                }
              }
            }
          }

          .duration-and-flight {
            @include mediaMax($sm) {
              display: none;
            }

            .time {
              font-size: 24px;
              line-height: 36px;
            }

            .small-text {
              font-size: 13px;
              line-height: 18px;
              color: #797C9A;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 30px;

    .price-and-cashback {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;

      .price {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;

        @include mediaMax($md) {
          font-size: 18px;
        }
      }

      .cashback {
        background-color: $secondary-color;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        width: fit-content;
        padding: 7px 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #fff;

        @include mediaMax($md) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    .book-button {
      border: none;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      padding: 18px 24px;
      text-decoration: none;
      cursor: pointer;

      @include mediaMax($md) {
        font-size: 18px;
        padding: 8px 15px;
      }
    }
  }
}