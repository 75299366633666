@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.recommendation-route-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @include mediaMax($xl) {
    gap: 10px;
  }

  @include mediaMax($md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .route-info {
    display: flex;
    gap: 10px;
    width: 440px;

    @include mediaMax($xl) {
      width: 350px;
    }

    &.mobile {
      width: 100%;
      flex-direction: column;
      gap: 0;

      .timings {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      .small-text {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .from,
    .to {
      width: 100px;

      .time {
        font-size: 24px;
        line-height: 36px;
      }

      .small-text {
        font-size: 11px;
        line-height: 18px;
        color: #797C9A;
      }
    }

    .from {
      text-align: right;
    }

    .duration {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 220px;

      .time {
        font-size: 14px;
      }

      .fly-line {
        display: flex;
        align-items: center;
        height: 4px;
        width: 100%;

        &>div {
          background-color: #dfe5ec;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          margin: 0 1px;

          &.transfer {
            background-color: #E8E9ED;
            height: 4px;
          }

          &.segment {
            height: 4px;
          }

          &.segment-1 {
            background-color: $primary-color;
          }

          &.segment-2 {
            background-color: $secondary-color;
          }

          &.segment-3 {
            background-color: $success-color;
          }

          &.segment-4 {
            background-color: rgba($color: #ff0000, $alpha: .8);
          }
        }
      }

      .transfers {
        display: flex;
        flex-direction: column;
        gap: 3px;
        font-size: 12px;
        font-weight: 500;
        color: #797C9A;

        .change-airport {
          text-align: center;
          color: $error-color;
        }

        .night-transfer {
          text-align: center;
          color: $primary-color;
        }
      }
    }
  }

  .route-addition {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: right;
    width: 150px;

    &.mobile {
      width: 100%;

      .icons-and-free-seats {
        justify-content: flex-start;
      }
    }

    .service-class {
      font-size: 14px;
      font-weight: 600;
    }

    .icons-and-free-seats {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      font-size: 14px;
    }

    .baggage {
      font-size: 11px;
    }
  }
}