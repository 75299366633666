@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.layout-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-image: url("../../assets/images//bodyBackgrounds//planes_bg.png");
  background-size: 500px;
  background-repeat: repeat;

  &.dark-theme{
    background-color: $body-my-profile;
    background-image: url("../../assets/images//bodyBackgrounds//planes_bg_dark.png");
    
  }
}
