@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

@mixin themeColorHandle($bg-color, $shodow-color, $filter, $text-color) {
  .swap-routes-button {
    background-color: $bg-color;
    box-shadow: 0px 0px 8px 0px $shodow-color;

    img {
      filter: $filter;
    }
  }

  .buttons-container {
    .clear-button {
      color: $text-color;
      border-color: $text-color;
      filter: $filter;

      svg {
        path {
          fill: $text-color;
        }
      }
    }
  }
}

.avia-search-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background-size: contain;

  &.dark-theme-avia-search-wrapper {
    @include themeColorHandle(
      $secondary-bg-dark,
      #bfa130,
      invert(0.7),
      $text-color-night
    );
  }

  &.light-theme-avia-search-wrapper {
    @include themeColorHandle(
      #ffffff,
      rgb(33 33 38 / 38%),
      none,
      $text-color-day
    );
  }

  .double {
    border: none;

    .from-date-block {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border: 1px solid rgb(0 0 0 / 35%);
    }

    .to-date-block {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border: 1px solid rgb(0 0 0 / 35%);
    }
  }

  @include mediaMax($md) {
    gap: 16px;
  }

  .avia-search-container {
    display: grid;
    grid-template-columns: 1fr 220px;
    width: 100%;
    gap: 8px;

    @include mediaMax($xl) {
      grid-template-columns: 1fr auto;
    }

    &.simple-route {
      @include mediaMax($lg) {
        max-width: 100%;
        grid-template-columns: 1fr;

        .sr-item-4 {
          grid-column: 1/3;
        }

        .sr-item-5 {
          grid-row: 3/4;
          grid-column: 1/2;
        }
      }

      @include mediaMax($md) {
        .fields-block {
          .sr-item-4 {
            grid-row: 2/3;
          }

          .sr-item-5 {
            grid-column: 1/3;
          }
        }
      }

      @include mediaMax($sm) {
        .fields-block {
          .sr-item-1 {
            grid-column: 1/3;
          }

          .sr-item-3 {
            grid-column: 1/3;
          }

          .sr-item-4 {
            grid-column: 1/3;
            grid-row: 3/4;
          }

          .sr-item-5 {
            grid-row: 4/5;
            grid-column: 1/3;
          }
        }
      }
    }

    &.advanced-route {
      gap: 8px;

      .fields-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .cf-item-1 {
          grid-column: 1/2;
        }

        .cf-item-2 {
          grid-column: 2/3;
        }

        .cf-item-3 {
          grid-column: 3/4;
        }

        .cf-item-5 {
          grid-column: 1/2;
        }
      }

      @include mediaMax($sm) {
        .fields-block {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .cf-item-1 {
            grid-column: 1/3;
          }

          .cf-item-2 {
            grid-column: 1/2;
          }

          .cf-item-3 {
            grid-column: 2/3;
          }

          .wide {
            grid-column: 1/2;
          }
        }
      }
    }

    .fields-block {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 1fr;
      gap: 8px;
      position: relative;

      @include mediaMax($lg) {
        grid-template-columns: 1fr 1fr;
      }

      @include mediaMax($sm) {
        flex-direction: column;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }

      .swap-routes-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 201px;
        top: calc(50% - 12px);
        z-index: 10;
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border: none;
        cursor: pointer;

        @include mediaMax($xl) {
          left: 195px;
        }

        @include mediaMax($lg) {
          left: 388px;
          top: 20px;
        }

        @include mediaMax($md) {
          left: 284px;
          top: 15px;
        }

        @include mediaMax($sm) {
          left: auto;
          right: 50%;
          top: 44px;
          transform: rotate(90deg) translateY(-50%);
          -webkit-transform: rotate(90deg) translateY(-50%);
          -moz-transform: rotate(90deg) translateY(-50%);
          -ms-transform: rotate(90deg) translateY(-50%);
          -o-transform: rotate(90deg) translateY(-50%);
        }
      }

      .field-block {
        position: relative;
      }
    }

    .add-route-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      background-color: #f1b101;
      border: none;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      box-shadow: 10px 12px 30px 0px rgba(44, 57, 89, 0.8);
      cursor: pointer;

      @include mediaMax($md) {
        font-size: 14px;

        img {
          width: 16px;
        }
      }

      @include mediaMax($sm) {
        margin-top: 3px;
        box-shadow: none;
        height: 52px;
        font-size: 14px;
      }

      &:disabled {
        background-color: #d9d9d9;
        cursor: default;
      }
    }

    &.advanced-route .fields-block {
      gap: 8px;

      .remove-route-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -5px;
        top: -5px;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background-color: #d82332;
        cursor: pointer;

        .icon {
          width: 12px;
          height: 12px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
        }
      }
    }

    .search-arrow {
      width: 24px;
    }

    &.cf-routes {
      grid-template-columns: 1fr;
    }
  }

  .buttons-container {
    opacity: 1;
    display: flex;
    transition-behavior: allow-discrete;
    justify-content: space-between;
    gap: 16px;
    transition: 100ms ease;

    @include mediaMax($lg) {
    }

    @include mediaMax($md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      width: 100%;
    }

    @include mediaMax($sm) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 15px;
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 2px 10px;
      background-color: transparent;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      font-weight: 500;

      @include mediaMax($md) {
        width: 100%;
      }

      svg {
        path {
          fill: #000000ad;
        }
      }
    }
  }

  .search-button {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: $search-input-heigth;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 2px 2px 10px 0px rgba(44, 57, 89, 0.8);
    cursor: pointer;

    svg {
      width: 24px;
    }

    @include mediaMax($xl) {
      width: 54px;

      span {
        display: none;
      }
    }

    @include mediaMax($lg) {
      width: 100%;

      span {
        display: block;
      }
    }

    @include mediaMax($md) {
      span {
        font-size: 14px;
        display: block;
      }
    }

    &.all {
      display: flex;
    }

    &.xl {
      @include mediaMin($xl) {
        display: flex;
      }
    }

    &.lg {
      @include mediaMinMax($lg, $xl) {
        display: flex;
      }
    }

    &.md {
      @include mediaMinMax($md, $lg) {
        display: flex;
      }
    }

    &.sm {
      @include mediaMinMax($sm, $md) {
        display: flex;
      }
    }

    &.xs {
      @include mediaMax($sm) {
        display: flex;
      }
    }
  }

  .change-flight-type-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 10px;
    height: 28px;
    font-size: 12px;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    &.button-dark-theme {
      svg {
        path {
          fill: #000;
        }
      }
    }

    svg {
      width: 20px;
    }

    @include mediaMax($lg) {
      height: auto;
    }

    @include mediaMax($lg) {
      font-size: 14px;
    }

    @include mediaMax($sm) {
      width: 100%;
    }
  }

  &.scroll-top {
    @include mediaMin($lg) {
      .buttons-container {
        opacity: 0;
        display: none;
        transition-behavior: allow-discrete;
        transition: 300ms ease 1s;
      }
    }
  }
}
