@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

@mixin themeColorHandle($bg-color, $border-color, $title-color) {
  .search-block {
    background-color: $bg-color;
    border-color: $border-color;

    h1 {
      color: $title-color;
    }
  }
}

.search-wrapper {
  margin-top: -76px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 96vh;
  z-index: 100;
  padding: 64px 0;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-color: rgb(0 0 0 / 18%);
  background-blend-mode: multiply;
  transition: 300ms ease;

  &.dark-theme-search-wrapper {
    @include themeColorHandle(
      rgb(0 0 0 / 43%),
      rgb(125 125 125 / 28%),
      $text-color-night
    );
  }

  &.light-theme-search-wrapper {
    @include themeColorHandle(
      rgb(255 255 255 / 46%),
      rgb(255 255 255 / 38%),
      $text-color-day
    );
  }

  &.min-height {
    margin-top: 0;
    min-height: fit-content;
    background-attachment: scroll;
    top: 0;
  }

  .down {
    padding: 0 !important;
  }

  @include mediaMax($md) {
    height: auto;
    width: 100%;
    padding: 30px 0;
  }

  @include mediaMax($sm) {
    min-height: 70vh;
    margin: 0;
  }

  &.light-theme {
    background-image: url("../../assets/images/planeBackgroundLight.jpg");
  }

  &.dark-theme {
    background-image: url("../../assets/images/planeBackgroundDark.jpg");
  }

  .search-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    padding: 0;

    .search-block {
      padding: 16px;
      box-shadow: 0px 0px 5px 0px #00000017;
      background-size: contain;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-width: 1px;
      border-style: solid;
      transition: 500ms ease;
      background-color: transparent;
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

      @include mediaMax($sm) {
        height: 100%;
        margin-top: -10px;
      }

      @include mediaMax($md) {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
      }

      @include mediaMax($sm) {
        margin: 10px;
      }
    }

    .title {
      margin: 8px;
      margin-top: 0;
      opacity: 1;
      transform: translateY(0);
      font-size: 24px;
      transition: 0.3s ease;
    }
  }

  &.scroll-top {
    @include mediaMin($lg) {
      padding-bottom: 8px;
      background-image: none;
      background-color: transparent;
      backdrop-filter: blur(16px);
      transition: 300ms ease 300ms;
      box-shadow: 0px 0px 10px 0px #0000004d;
      border-bottom: 1px solid #ffffff4a;
      position: sticky;

      .title {
        transition: 0.3s ease;
        font-size: 0;
        opacity: 0;
        transform: translateY(47px);
      }
      .search-block {
        background-color: transparent !important;
        border: none;
        box-shadow: none;
        padding-bottom: 0;
        transition: 500ms ease;
      }
    }
  }
}
