@import "../../../styles/variables.scss";

.checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .label {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 28px;

    &.light-theme{
      color: #333;
    }
    &.dark-theme{
      color: $primary-background;
    }

    .chacked-icon {
      path {
        stroke: #fff;
      }
    }
  }

  .checkbox-input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    cursor: pointer;
    
  }

  .checkbox-block {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    background-color: #fff;
            &.checked
{
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &.has-label {
      margin-right: 10px;
      
    }
  }
}
