@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.booking-agreements-wrapper {
  grid-column: 1/2;

  .agreements-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: 1/2;

    .agreements-block {
      display: flex;
      gap: 10px;

      @include mediaMax($sm) {
        font-size: 14px;
      }

      a {
        color: $primary-color;
        text-decoration: none;
      }
    }

    .warning {
      display: flex;
      gap: 10px;

      p {
        color: $primary-color;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .submit-button {
    display: block;
    margin: 0 auto;
    width: 500px;
    height: 60px;
    font-size: 24px;
    border: none;
    position: relative;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .submit-button-loading-block {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-color;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    @include mediaMax($sm) {
      width: 100%;
      height: auto;
      font-size: 18px;
    }
  }
}