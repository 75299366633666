@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

@mixin themeColorHandler($primary-theme-color, $text-color) {
  h1 {
    color: $primary-theme-color
  }

  .telegram-logo {
    path {
      fill: $primary-theme-color
    }
  }

  span,
  small {
    color: $text-color;
  }
}

.application-received {
  text-align: center;
  gap: 32px;
  display: grid;
  margin: 40px auto;
  justify-content: center;
  min-height: 442px;

  &.dark-theme-application-received {
    @include themeColorHandler($primary-color-dark, $text-color-night);
  }

  &.light-theme-application-received {
    @include themeColorHandler($primary-color, $text-color-day);
  }

  h1 {
    color: $primary-color;
    margin-bottom: 12px;
    font-size: 32px;
  }

  .suscribe {
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 16px;
    max-width: 1000px;
    margin: 8px;

    button {
      font-size: 18px;
      margin-bottom: 16px;
      max-width: 220px;
    }

    .telegram-logo {
      width: 100px;
      height: 100px;
    }

    small {
      display: block;
      margin-bottom: 16px;
      font-size: 16px;
      padding: 10px 0;
    }
  }

  a {
    color: $primary-color;
    text-decoration: none;
    font-size: 16px;
  }

  @include mediaMax($sm) {
    margin: 50px auto;

    h1 {
      font-size: 26px;
    }
  }
}