@import "../../../styles/variables.scss";

.recommendation-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  &.recommendation-icons-dark-theme {
    svg {
      path {
        stroke: $primary-color-dark;
      }
    }
  }

  .disabled-icon {
    path {
      stroke-opacity: 0.5;
    }
  }
}
