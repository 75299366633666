@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.app-to-refund-wrapper {
  .app-to-refund-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 15px;

    h1 {
      margin: 40px 0px 70px;
      color: #000;
      font-size: clamp(20px, 5vw, 40px);
      font-style: normal;
      font-weight: 700;
      line-height: 0px;

      &.dark-theme {
        color: $text-color-night;
      }
    }

    p {
      color: var(--gray-900, #12131a);
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 5px;

      &.dark-theme {
        color: $text-color-night;
      }
    }

    .passenger-inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    InputUI {

      &.touched {
        border: 1px solid red;
      }

      &::placeholder {
        color: var(--gray-200, #babdcc);
        font-family: Manrope;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }


      input {
        width: 866px;
        height: 50px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border: 1px solid var(--gray-200, #babdcc);
        background: var(--White, $primary-background);
        padding: 13px 20px;
                &.touched
{
          border: 1px solid $error-color;
        }

        &::placeholder {
          color: var(--gray-200, #babdcc);
          font-family: Manrope;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        }

        @include mediaMax($sm) {
          width: 300px;
        }

        @include mediaMax($md) {
          width: 100px;
        }

        @include mediaMax($lg) {
          width: 400px;
        }
      }

      @include mediaMax($md) {
        width: 100px;
      }

      @include mediaMax($lg) {
        width: 400px;
      }
    }
  }

  input-ui {
    &.dark-theme {
      background-color: white;
      color: black;
    }
  }

  h2 {
    margin: 20px;

    @media screen and (max-width: 1024px) {
      margin: 10px;
    }

    &.dark-theme {
      color: $text-color-night;
    }
  }

  .add-person {
    position: relative;
    cursor: pointer;

    p {
      color: #004aad;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      position: relative;
      left: 360px;

      &.dark-theme {
        color: $text-color-night;
      }

      @media screen and (max-width: 1024px) {
        left: 0;
      }
    }
  }

  .input-file {
    border: none;
    width: 400px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    padding: 8px;
        @include mediaMax($sm)
{
      width: 100%;
    }

    &.dark-theme {
      color: $text-color-night;
    }
  }

  .input-file-img {
    border: 0.3px solid var(--gray-200, #babdcc);
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    box-shadow: 0px 0px 0px 2px rgba(217, 229, 255, 0.75);
    margin-bottom: 10px;
}

  .input__wrapper {
    width: 100%;
    position: relative;
    margin: 15px 0;
    text-align: center;
    display: flex;
    justify-content: start;
  }

  .input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  .input__file-icon-wrapper {
    padding: 5px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #fff;

    .input__file-icon {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
    }
  }

  .input__file-button-text {
    line-height: 1;
    margin-top: 1px;
    padding-right: 4px;
  }

  .input__file-button {
    background: #004aad;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /*Выравниваемсодержимоеполевомукраю*/
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    padding: 4px;

    &.dark-theme-file-button {
      background-color: $primary-color-dark;
    }

    &.light-theme-file-button {
      background-color: $primary-color;
    }
  }

}

.app-button {
  position: relative;
  margin: 60px 0px;

  &.dark-theme-app-button {
    button {
      background-color: $primary-color-dark;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: var(--White, $primary-background);
    font-family: SF Pro Display;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    padding: 8px;

    @mediascreenand(max-width: 1024px) {
      width: 100%;

    }

    @media screen and (max-width: 768px) {
      width: 100%;

    }
  }

  button.success {
    background-color: #4CAF50;
    /* Зелёный цвет */
    pointer-events: none;
    /* Отключаем кликабельность */
    opacity: 0.7;
    /* Уменьшаем прозрачность, чтобы выделить, что кнопка неактивна */
  }

  .success-Icon {
    path {
      stroke: rgb(255, 255, 255);
    }
  }
}