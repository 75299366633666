@import "../../styles/variables.scss";
@import "../../styles//mixins.scss";

$dark-gradient: linear-gradient(304deg, rgba(0, 0, 0, 1) 0%, rgba(0, 42, 78, 1) 100%);

@mixin themeColorHandle($bg, $bg-gradient, $text-color) {
  background: rgb(0, 0, 0);
  background: $bg-gradient;

  .not-found-page-wrapper {

    p {
      color: $text-color;
    }
  }
}

.not-found-page-section {

  &.dark-theme-not-found-page {
    @include themeColorHandle(rgb(0, 0, 0), $dark-gradient, $text-color-night)
  }

  &.light-theme-not-found-page {
    @include themeColorHandle(transparent, transparent, $text-color-day)
  }

  .not-found-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    .block-404 {
      display: flex;
      align-items: center;
      margin-bottom: clamp(20px, 5vw, 68px);
      font-size: clamp(9rem, 24vw, 21rem);
      font-weight: 600;
      color: #c98519;

      :nth-child(1),
      :nth-child(3) {
        opacity: 0.3;
      }

      :nth-child(1) {
        transform: translateX(20%);
        -webkit-transform: translateX(20%);
        -moz-transform: translateX(20%);
        -ms-transform: translateX(20%);
        -o-transform: translateX(20%);
      }

      :nth-child(3) {
        transform: translateX(-20%);
        -webkit-transform: translateX(-20%);
        -moz-transform: translateX(-20%);
        -ms-transform: translateX(-20%);
        -o-transform: translateX(-20%);
      }

      img {
        z-index: 111;
        width: clamp(130px, 21vw, 296px);
      }
    }

    p {
      margin-bottom: 30px;
      font-size: clamp(16px, 3vw, 36px);
      text-align: center;

      @include mediaMax($sm) {
        margin-bottom: 16px;
      }
    }

    button {
      font-size: 18px;

      @include mediaMax($sm) {
        font-size: 14px;
      }
    }
  }
}