@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.corporate-transactions-wrapper {
  overflow: hidden;

  .corporate-transactions-report {
    margin: 80px 0;

    @include mediaMax($sm) {
      margin: 60px 0;
    }

    .table-wrap {
      left: 0;
      margin-top: 50px;
    }
  }
}