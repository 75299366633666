@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

$border-color: #e3e3e3;
$charts-level: #6092D6;

@mixin themeColorHandle($bg-color, $secondary-bg, $text-color) {
  .charts-wrapper {
    background-color: $bg-color;
  }

  .filters {
    select {
      background-color: $secondary-bg;
      color: $text-color;
    }
  }
}

.analitcs-graphic {
  margin-top: 30px;
  margin-bottom: 70px;
  position: relative;

  &.dark-theme-analitcs-graphic {
    @include themeColorHandle(#000, $secondary-bg-dark, $text-color-night);
  }

  &.light-theme-analitcs-graphic {
    @include themeColorHandle(white, $secondary-bg-light, $text-color-day);
  }

  @include mediaMax($sm) {
    .recharts-default-legend {
      display: none;
    }
  }

  .loading-dots {
    position: absolute;
    bottom: 222px;
    left: 50%;
    transform: translateY(-50%);
    z-index: 111;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .header {
    gap: 40px;
    margin: 30px 0 10px;

    @include mediaMax($sm) {
      flex-direction: column;
      margin-top: 0;
      gap: 20px;
    }

    .title {
      text-align: center;
      margin-bottom: 30px;
      font-size: clamp(24px, 3vw, 34px);
    }

    .section-date-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      @include mediaMax($lg) {
        grid-template-columns: 1fr;
      }

      @include mediaMax($sm) {
        width: 100%;
      }

      .filters {
        &.dark-theme {
          background-color: rgb(199, 199, 199);
          color: $primary-background;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          border: 1px solid rgba(77, 143, 231, 0.4);
        }

        select {
          padding: 9px 16px;
          outline: none;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          border: 1px solid rgba(77, 143, 231, 0.4);

          option {
            border: 1px solid rgba(77, 143, 231, 0.4);
          }

          @include mediaMax($sm) {
            width: 100%;
          }
        }
      }

      .checkbox-wrap-block {
        display: flex;
        gap: 24px;
        justify-self: end;

        @include mediaMax($lg) {
          justify-self: normal;
          justify-content: space-between;
        }

        @include mediaMax($sm) {
          flex-direction: column;
          gap: 0;
          width: 100%;
        }

        .commission-fees {
          display: none;
        }
      }
    }

    .filters-action {
      display: flex;
      gap: 16px;

      @include mediaMax($lg) {
        justify-content: space-between;
      }

      @include mediaMax($sm) {
        width: 100%;
        flex-direction: column-reverse;
      }
    }

    .date-block {
      display: flex;

      @include mediaMax($sm) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      .input:nth-child(1) {

        @include mediaMax($sm) {
          border-radius: 8px;
        }
      }

      .input:nth-child(2) {

        @include mediaMax($sm) {
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
        }
      }

      .input {
        border: 1px solid #4d8fe766;
        padding: 9px 16px;
        color: #2c2c2c;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        margin-right: 10px;
        width: 137px;

        &.dark-theme {
          color: $primary-background;
        }

        @include mediaMax($sm) {
          width: 100%;
        }

        &:focus {
          outline-color: $primary-color;
        }

        @include mediaMax($sm) {
          margin: 0;
        }
      }

      button {
        min-width: 130px;

        @include mediaMax($sm) {
          margin-left: 0;
          font-size: 14px;
          grid-column: 1/3;
        }
      }
    }
  }

  .charts-wrapper {
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
  }

  .info {
    margin: 36px auto;

    @include mediaMax($sm) {
      margin: 40px auto;
    }

    .info-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @include mediaMax($md) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
      }

      .item {
        width: 100%;
        padding: 8px 24px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        h4 {
          margin: 12px 0;
          font-size: 16px;

          :nth-child(1) {
            font-weight: 500;
          }

          :nth-child(3) {
            font-weight: 500;
          }
        }
      }
    }
  }
}