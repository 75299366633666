@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

@mixin themeColorHandle($title-color) {
  .registration-title {
    color: $title-color;
  }
}
.registration-wrapper {
  flex-direction: column;
  padding: 40px 30px;
  display: grid;
  gap: 26px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  &.dark-theme-registration {
    @include themeColorHandle($primary-color-dark);
  }

  &.light-theme-registration {
    @include themeColorHandle($primary-color);
  }

  .registration-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .arrow-to-right {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px;
    cursor: pointer;
  }

  .form {
    display: grid;
    gap: 26px;

    input {
      padding: 16px;
    }

    .registration {
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      width: 60%;
      margin: 0 auto;
      display: block;
}

    small {
      color: #e53935;
      font-size: 14px;
      margin: 4px 0;
      padding: 0;
    }

    .company {
      color: #e53935;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .to-login {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    :nth-child(2) {
      color: $primary-color;
      font-weight: 600;
      cursor: pointer;
    }
  }

  @include mediaMax($sm) {
    padding: 25px 0 10px;
  }

  .enter {
    text-align: center;

    .enter-img {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
    
    h4 {
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 16px;
    }

    .enter-button {
      width: 200px;
    }
  }
}
