@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes dropdownIn {
  0% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
  }

  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes dropdownOut {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
  }
}

.dropdown-wrapper {
  display: none;
  justify-content: center;
  position: absolute;
  top: calc(100% + 8px);
  z-index: 151;
  width: 100%;
  cursor: default;

  @include mediaMax($sm) {
    top: calc(100% + 4px);
  }

  &>* {
    user-select: none;
  }

  &.active {
    display: flex;
  }

  &.from-right {
    right: 0;
  }

  &.from-left {
    left: 0;
  }
}

.dropdown-block {
  display: block;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  animation: dropdownOut 0.2s forwards, fadeOut 0.2s forwards;
  -webkit-animation: dropdownOut 0.2s forwards, fadeOut 0.2s forwards;
    &.active
{
    animation: dropdownIn 0.2s forwards, fadeIn 0.2s forwards;
    -webkit-animation: dropdownIn 0.2s forwards, fadeIn 0.2s forwards;
  }

  &.no-padding {
    padding: 0;
  }
}