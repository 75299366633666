@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

@mixin themeColorHandle($title-color, $moon, $desc-span-color, $img-color) {
  .description-text {

    .moon {
      visibility: $moon;
    }

    h2 {
      color: $title-color;
    }

    p {

      span {
        color: $desc-span-color;
      }
    }

    .description-button {
      img {
        filter: $img-color;
      }

      span {
        color: $desc-span-color;
      }
    }
  }
}

.description-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 64px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;

  &.dark-theme-description-section {
    @include themeColorHandle($primary-color-dark, visible, white, invert(1))
  }

  &.light-theme-description-section {
    @include themeColorHandle($primary-color, hidden, $primary-color, none)
  }

  @include mediaMax($sm) {
    padding: 0 10px;
    height: auto;
    text-align: center;
  }

  ._bg-enter-page {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;

    @include mediaMax($lg) {}

    img {
      width: 50%;
      height: 100%;

      @include mediaMax($md) {
        width: 100%;
      }

      @include mediaMax($sm) {
        width: 150%;
      }
    }
  }

  .description-text-image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    height: 100%;
    align-items: center;

    @include mediaMax($lg) {
      gap: 0;
    }

    @include mediaMax($md) {
      grid-template-columns: 1fr;
    }

    .description-text {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      position: relative;
      height: 100%;
      max-width: 70%;
      position: relative;

      .moon {
        position: absolute;
        top: 10%;
        left: 20%;
        width: 100px;
      }

      @include mediaMax($xxl) {
        max-width: 80%;
      }

      @include mediaMax($xl) {
        max-width: 90%;
      }

      @include mediaMax($sm) {
        gap: 16px;
        margin-top: 24px;
        max-width: 100%;
      }

      h2 {
        position: relative;
        font-size: 54px;
        line-height: 1.15;
        font-weight: 700;

        @include mediaMax($xl) {
          font-size: 40px;
        }

        @include mediaMax($lg) {
          font-size: 36px;
        }

        @include mediaMax($md) {
          font-size: 30px;
        }

        @include mediaMax($sm) {
          font-size: 24px;
        }

        // span {
        //   text-decoration: underline;
        //   text-decoration-color: $primary-color;
        //   text-underline-offset: 9px;
        // }
      }

      p {
        position: relative;
        font-size: 20px;
        line-height: 1.5;
        font-weight: 400;

        @include mediaMax ($lg) {
          font-size: 18px;
        }

        @include mediaMax ($md) {
          font-size: 16px;
        }

        @include mediaMax($sm) {
          font-size: 14px;
        }
      }

      .description-button {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        @include mediaMax($xl) {
          gap: 16px;
        }

        @include mediaMax($sm) {
          display: flex;
          flex-direction: column;
          max-width: 230px;
          margin: 0 auto 30px auto;
        }

        button {
          font-size: 18px;
          padding: 15px 40px;
          width: 100%;

          @include mediaMax($md) {
            font-size: 16px;
          }
        }

        .enter-button {
          background-color: transparent;
          color: $primary-color;
          font-weight: 600;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            width: 40px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
          }
        }
      }

      ._plane {
        position: absolute;
        top: 0;
        right: -70%;
        transform: rotate(2deg);
        -webkit-transform: rotate(2deg);
        -moz-transform: rotate(2deg);
        -ms-transform: rotate(2deg);
        -o-transform: rotate(2deg);

        @include mediaMax($md) {
          position: static;
        }

        img {
          max-width: 90%;

          @include mediaMax($sm) {
            max-width: 40%;
          }
        }
      }
    }

    .description-image {
      text-align: center;
      position: relative;

      img {
        max-width: 100%;
      }

      .image-one {
        img {
          position: absolute;
          top: 100px;
          right: 240px;
          width: 200px;
          height: 138px;

          @include mediaMax ($sm) {
            top: 100px;
            left: 0px;
          }

          @include mediaMax ($lg) {
            top: 100px;
            right: 0px;
          }
        }
      }
    }

    .image-two {
      img {
        position: absolute;
        bottom: 50px;
        left: 200px;
        width: 200px;
        height: 138px;

        @include mediaMax ($lg) {
          bottom: 50px;
          left: 0px;
        }
      }
    }
  }
}