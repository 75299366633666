@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.avia-order-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
  padding: 30px;
  box-shadow: 0px 2px 4px 0px rgba(23, 25, 35, 0.25);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;

  @include mediaMax($md) {
    padding: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  & .block {
    display: grid;
    grid-template-columns: 0.6fr 1fr;

    @include mediaMax($md) {
      gap: 30px;
    }

    @include mediaMax($sm) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

    }

    & .label {
      font-size: 32px;
      font-weight: 700;

      @include mediaMax($xl) {
        font-size: 24px;
      }

      @include mediaMax($md) {
        font-size: 20px;
      }
    }

    & .info {
      display: flex;
      flex-direction: column;

      &.booking {
        gap: 10px;
        padding: 5px 0;
        flex-grow: 1;

        & .row {
          display: flex;
          justify-content: space-between;

          button {
            background-color: transparent;
            border: 2px solid $primary-color;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            padding: 8px 20px;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            cursor: pointer;

          }

          & .label {
            font-size: 16px;
            line-height: 28px;
            font-weight: 300;
          }

          & .value {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
          }
        }

        @include mediaMax($md) {
          padding: 0;
        }
      }

      &.passengers {
        display: flex;
        flex-direction: column;
        gap: 30px;
        flex-grow: 1;

        & .passenger {
          display: flex;
          flex-direction: column;
          gap: 10px;

          & .name {
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;

            @include mediaMax($xl) {
              line-height: 28px;
            }

            @include mediaMax($md) {
              font-size: 20px;
              line-height: 24px;
            }
          }

          & .row {
            display: flex;
            align-items: center;
            gap: 50px;

            @include mediaMax($md) {
              gap: 30px;
            }

            @include mediaMax($sm) {
              gap: 50px;
            }

            & .label {
              width: 150px;
              font-size: 16px;
              line-height: 28px;
              font-weight: 300;

              @include mediaMax($md) {
                width: 150px;
                font-size: 14px;
                line-height: 20px;
              }

              @include mediaMax($sm) {
                width: 100px;
              }
            }

            & .value {
              flex-grow: 1;
              font-size: 18px;
              font-weight: 400;
              line-height: 28px;

              @include mediaMax($md) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}