@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.recharts-wrapper {
  position: relative;
  cursor: default;
  width: 1300px;
  height: 400px;
  border: 1px solid #ababab7d;
  border-top-width: 0;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
}


@mixin themeColorHandle($bg) {
  .recharts-default-tooltip {
    background-color: $bg !important;
  }
}

.dark-theme-recharts {
  @include themeColorHandle($secondary-bg-dark)
}

.ligth-theme-recharts {
  @include themeColorHandle($secondary-bg-light)
}