@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.presentatiton-img-wrapper {
  img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
}