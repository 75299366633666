@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

$avia-bg-default: #FFB740;
$avia-bg-one: #3679D6;
$avia-bg-two: #FF8940;
$avia-bg-three: #4D37DA;
$avia-bg-four: #00AA84;

@mixin themeColors($text-color) {

  .charter-text {
    color: $text-color;
  }
} 

.recommendation-wrapper {
  display: flex;
  width: 100%;

  .recommendation-info {
    display: flex;
    flex-direction: column;
    box-shadow: -8px 7px 8px 0px #00000040;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    width: 650px;

    .color-aviacompany {
      background-color: $avia-bg-default;
      color: #ffffff;

      &.one-bg {
        background-color: $avia-bg-one;
      }

      &.two-bg {
        background-color: $avia-bg-two;
      }

      &.three-bg {
        background-color: $avia-bg-three;
      }

      &.four-bg {
        background-color: $avia-bg-four;
      }
    }

    @include mediaMax($xl) {
      width: 550px;
    }

    @include mediaMax($md) {
      width: 380px;
    }

    .ticket-company-bottom {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }

  .recommendation-price {
    display: flex;
    flex-direction: column;
    box-shadow: 8px 7px 8px 0px #00000040;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    width: 300px;

    .color-aviacompany {
      background-color: $avia-bg-default;

      &.one-bg {
        background-color: $avia-bg-one;
      }

      &.two-bg {
        background-color: $avia-bg-two;
      }

      &.three-bg {
        background-color: $avia-bg-three;
      }

      &.four-bg {
        background-color: $avia-bg-four;
      }
    }

    @include mediaMax($xl) {
      width: 250px;
    }

    @include mediaMax($md) {
      width: 220px;
    }
  }

  .top {
    height: 50px;
    border-radius: 15px 15px 0 0;
    -webkit-border-radius: 15px 15px 0 0;
    -moz-border-radius: 15px 15px 0 0;
    -ms-border-radius: 15px 15px 0 0;
    -o-border-radius: 15px 15px 0 0;

    &.info {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 14px 30px 10px 30px;

      &>div {
        display: flex;
        background-color: #fff;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding: 5px;
        height: 32px;

        img {
          height: 100%;
        }
      }

      h2 {
        color: #fff;
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;

        @include mediaMax($xl) {
          font-size: 16px;
        }
      }
    }
  }

  .body {
    flex-grow: 1;
    box-shadow: none;

    &.secondary-bg-dark-theme {
      box-shadow: none;

      @include themeColors($secondary-color)
    }

    &.secondary-bg-light-theme {
      @include themeColors($primary-color)
    }

    &.price {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      padding: 30px 0;

      h2 {
        font-size: 32px;
        font-weight: 600;
      }

      .cashback {
        background-color: $secondary-color;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        width: fit-content;
        padding: 7px 10px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
      }

      .choose-button {
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border: none;
        font-size: 20px;
        font-weight: 500;
        padding: 10px 25px;
        cursor: pointer;
      }
    }

    &.info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      padding: 30px 15px;
    }
  }


  .bottom {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
    -ms-border-radius: 0 0 15px 15px;
    -o-border-radius: 0 0 15px 15px;

    &.price {
      justify-content: center;

      &>img {
        margin-top: -5px;
      }
    }

    &.info {
      padding-left: 30px;
      color: rgba($color: #fff, $alpha: .8);
      line-height: 0%;
    }
  }

  &.mobile {
    .recommendation-info {
      width: 100%;
      box-shadow: 0 0 15px 0px #00000040;

      .top {
        height: 40px;
        padding: 14px 15px 10px 15px;

        &>div {
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          padding: 5px;
          height: 24px;

          img {
            height: 100%;
          }
        }
      }

      .body {
        padding: 15px;
        box-shadow: none;

        &.secondary-bg-dark-theme {
          box-shadow: none;
        }

        &.info {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }

        .routes {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .price {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          gap: 10px;

          h2 {
            font-size: 18px;
            font-weight: 600;
          }

          .cashback {
            background-color: $secondary-color;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            width: fit-content;
            padding: 3px 5px;
            font-size: 12px;
            font-weight: 600;
            color: #fff;

          }

          .choose-button {
            background-color: $primary-color;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            color: #fff;
            border: none;
            font-size: 14px;
            font-weight: 500;
            padding: 8px 15px;
            cursor: pointer;

          }
        }
      }

      .bottom {
        height: 40px;
        padding-left: 15px;
        font-size: 12px;
      }
    }
  }
}