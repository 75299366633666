@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.header-balance-wrapper {
  @include mediaMax($md) {
    display: flex;
    gap: 5px;
    padding: 5px;
    padding-left: 70px;
    margin-top: 140px;
    position: absolute;
    left: 5px;
  }

  @include mediaMax($sm) {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .header-balance-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 1px solid $primary-color;
    cursor: pointer;
    border: 1px solid;
    padding: 6px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    span {
      display: block;
      text-wrap: nowrap;
    }
  }

  .dropDownWin {
    height: auto;
    right: auto;
    width: 25vw;
    margin: -47px 0px 0 -50px;

    @include mediaMax($md) {
      top: 110px;
      right: -281px;
      width: 380px;
    }
  }

  .balance-btn {
    padding: 20px;
    position: relative;
  }

  .nav-link-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0 15px 0;
  }

  .header-charge-balance {
    //margin-left: auto;
    border: none;
    text-align: center;
    transition: all transform 0.2s ease;
    -webkit-transition: all transform 0.2s ease;
    -moz-transition: all transform 0.2s ease;
    -ms-transition: all transform 0.2s ease;
    -o-transition: all transform 0.2s ease;

    &:active {
      transform: scale(0.97);
      -webkit-transform: scale(0.97);
      -moz-transform: scale(0.97);
      -ms-transform: scale(0.97);
      -o-transform: scale(0.97);
    }

    //кнопка Пополнить баланс
    button {
      cursor: pointer;
      border: none;
      background-color: inherit;
      font-size: 16px;
      color: $primary-color;
      font-weight: 500;

      @include mediaMax($sm) {
        font-size: 12px;
      }

      &.light-theme {
        color: $primary-color;
      }

      &.dark-theme {
        color: #fff;
      }
    }
  }

  .hidden {
    display: none;
  }

  .header-balance-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 5px;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      background-color: $success-color;
      margin-top: 10px;
}

    button {
      cursor: pointer;
      border: none;
      font-size: 16px;
      background-color: inherit;
      font-weight: 400;
      text-align: center;
      padding: 2px;
    }

    p {
      font-weight: 400;
      text-align: center;
      padding: 2px;
      text-wrap: nowrap;

      span {
        font-weight: 300;

        &.light-theme {
          color: black;
        }

        &.dark-theme {
          color: white;
        }
      }
    }
  }

  .header-credit-limit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    position: relative;
    margin-top: 40px;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 5px;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      background-color: $error-color;
      margin-top: 5px;
}

    p {
      font-weight: 400;
      text-align: center;
      padding: 2px;
      text-wrap: nowrap;

      span {
        font-weight: 300;
        margin-left: auto;
      }
    }
  }
}