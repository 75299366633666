@import "../../../styles/variables.scss";

.header-nav-features-menu {
  position: relative;

  .features-icon-menu {
    width: 23px;
    height: 23px;

    &.light-theme {
      circle {
        fill: $primary-color;
      }
    }

    &.dark-theme {
      circle {
        fill: $bg-header-light;
      }
    }
  }

  img {
    width: 18px;
    height: 18px;
  }

  @media screen and (max-width: 600px) {
    position: static;
  }

  &-button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .header-nav-features-menu-modal-ui {
    .header-nav-features-menu-modal-section {
      display: flex;
      flex-direction: column;

      .header-nav-features-menu-modal {
        display: flex;
        gap: 10px;
        padding: 10px;

        .wrapper-icon {
          display: grid;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          background-color: $primary-color;
        }

        p {
          align-self: center;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }
}