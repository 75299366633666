@import "../../../styles/variables.scss";

.recommendation-tariffs-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100px;
  }

  .tariff {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 390px;
    height: 460px;
    padding: 40px 30px;
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
    border: 0.5px solid #DADADA;

    &.active {
      border-color: $primary-color;
    }

    .price {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .list-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .list-item-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          color: #686870;
        }
      }
    }

    .choose-button {
      border: none;
      background-color: $primary-color;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      padding: 10px 40px;
      text-decoration: none;
      cursor: pointer;
    }
  }
}