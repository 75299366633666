@import "../../styles/variables.scss";

@mixin themeColorHandler($text-color) {
  p {
    color: $text-color;
  }
}
 
.orders-is-empty {

  &.dark-theme-orders-is-empty {
    @include themeColorHandler($text-color-night)
  }

  &.light-theme-orders-is-empty {
    @include themeColorHandler($text-color-day)
  }

  p {
    font-size: 18px;
    margin: 18px 0;
  }
}