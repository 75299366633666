@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.success-purchase-corporate-wrapper {
  margin: 20px;

  .success-purchase-corporate-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: clamp(20px, 4vw, 32px);
    text-align: center;
    color: $primary-color ;
    margin-bottom: 5px;
  }

  .success-purchase-corporate-telegram {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid rgba(0, 0, 255, 0.2);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px;
    gap: 10px;
    margin-top: 30px;
        img
{
      width: 70px;
    }

    p {
      text-align: center;
      margin-bottom: 20px;
    }

    a {
      color: #FFF;
      text-decoration: none;
    }
  }
}