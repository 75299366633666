$primary-color: #004AAD;
$primary-color-dark: #BFA130;
$secondary-color: #F7B407;
$secondary-bg-dark: #252525;
$secondary-bg-light: #ffffff;
$box-shadow: 0px 2px 4px 0px #0000004f;
$primary-background: #F0F0F1;
$primary-text-color: #000000;
$secondary-text-color: #2C3959;
$success-color: #00B712;
$hold-color: #F1B101;
$error-color: #FF6200;
$small-tag-color: #9b9b9b;
$primary-background: #F0F0F1;
$fly-color:#edf7ff;
$bg-header-light: white;
$text-button-dark: #000000;
$text-color-day: #353535;
$text-color-night: #d3d3d3;
$bg-header-dark: #000;
$bg-modal:#202124;
$dark-link-bg:#207bdc;
$dark-bg-for-btn: #202124;
$body-pageBg-dark:rgb(17, 17, 17);
$search-input-heigth: 54px;
$search-input-paddings: 19px 15px 0 20px;
$body-order-cards-bg: #202124;
$body-my-profile:rgb(18, 18, 18);
$bode-of-passenger-list-card:rgb(47, 47, 47);
$border-bottom: rgb(134, 134, 134); 
$body-download-card-dark:rgb(57, 57, 57);

$input-background-dark: #353535;

$border-color-dark: rgb(57 57 57);
$border-color-light: #e0e0e0;

$xs: 0;
$sm: 650px;
$md: 850px;
$lg: 1150px;
$xl: 1350px;
$xxl: 1450px;