@import "./variables.scss";
@import "./mixins.scss";

.container {
  width: 100%;
  max-width: 1330px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;

  @include mediaMax($xl) {
    max-width: 1130px;
  }

  @include mediaMax($lg) {
    max-width: 830px;
  }

  @include mediaMax($md) {
    max-width: 630px;
  }

  @include mediaMax($sm) {
    max-width: 100%;
  }
}


//use it in you JSX ${theme === "dark" ? "secondary-bg-dark-theme" : "secondary-bg-light-theme"}
@mixin secondaryBlockItem($theme-bg-color, $theme-text-color, $theme-primary-color, $theme-shadow-color) {
  background-color: $theme-bg-color;
  color: $theme-text-color;
  box-shadow: 0px 0px 5px 0px $theme-shadow-color;
  border: 1px solid rgb(255 255 255 / 10%);

  h2, h3, h4, h5 {
    color:$theme-primary-color;
  }

  a {
    color: $theme-text-color;
  }
}
.secondary-bg-light-theme {
  @include secondaryBlockItem($secondary-bg-light, $text-color-day, $primary-color, #0000002e);
}
.secondary-bg-dark-theme {
  @include secondaryBlockItem($secondary-bg-dark, $text-color-night, $primary-color-dark, rgb(255 202 115 / 42%));
}


//use it in you JSX ${theme === "dark" ? "tertiary-block-dark-theme" : "tertiary-block-light-theme"}
@mixin themeThirdBlock($theme-third-bg-color, $theme-third-text-color) {
  background-color: $theme-third-bg-color;
  color: $theme-third-text-color;
}
.tertiary-block-dark-theme {
  @include themeThirdBlock(#3b3b3b, #fff);
}

.tertiary-block-light-theme {
  @include themeThirdBlock(#F5F5F7, #2C3959);
}


//use it in you JSX ${theme === "dark" ? "_dark-icon-bg" : "_light-icon-bg"}
@mixin iconThemeColorHandle($bg-color, $text-color, $img-filter) {
  background-color: $bg-color;
  color: $text-color;

  img {
    filter: $img-filter;
  }
}
._light-icon-bg {
  @include iconThemeColorHandle($primary-color, #fff, none);
}

._dark-icon-bg {
  @include iconThemeColorHandle($primary-color-dark, #000, invert(1));
}

//use it in you JSX ${theme === "dark" ? "button-dark-theme" : "button-light-theme"}
.button-light-theme {
  background-color: $primary-color;
  color: #fff;

  svg {
    path {
      stroke: #fff;
    }
  }
}
.button-dark-theme {
  background-color: $primary-color-dark;
  color: #000;

  svg {
    stroke: #000 !important;

    path {
      stroke: #000 !important;
    }
  }
}


//use it in you JSX ${theme === "dark" ? "secondary-button-dark" : "secondary-button-light"}
.secondary-button-dark {
  background-color: transparent !important;
  border: 1px solid $primary-color-dark !important;
  color: $primary-color-dark !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.secondary-button-light {
  background-color: transparent !important;
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

//use it in you JSX ${theme === "dark" ? "dark-theme-input" : "light-theme-input"}
@mixin themeColorInputs($inputBG, $inputBorder, $inputTextColor) {
  background-color: $inputBG;
  border: 1px solid $inputBorder;
  color: $inputTextColor;
}
.dark-theme-input {
  @include themeColorInputs($input-background-dark, $border-color-dark, $text-color-night);
}
.light-theme-input {
  @include themeColorInputs(transparent, $border-color-light, $text-color-day);
}