@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.analitics-graph{
    &.dark-theme{
        
        h1, p, span, labal{
            color: $primary-background;
        }
    }
}