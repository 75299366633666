@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.avia-filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 15px;

  &.dark-theme {
    background-color: $secondary-bg-dark;

  }


  @include mediaMax($lg) {
    padding: 0 20px 0 0;
    width: 100%;
  }

  button {
    font-size: 16px;
    letter-spacing: 2px;
  }
}

.modal-ui {
  &.dark-theme {
    background-color: $body-my-profile;
    color: white;
  }
}

.filter-button {
  position: sticky;
  bottom: 30px;
  order: 2;
  background-color: $primary-color;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 25px;
  cursor: pointer;

}

.find-button {
  display: block;
  background-color: $primary-color;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 25px;
  cursor: pointer;
  margin: 20px auto;

}

button {
  &:active {
    transform: scale(0.99);
    -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -ms-transform: scale(0.99);
    -o-transform: scale(0.99);

    transition: 100ms;
    -webkit-transition: 100ms;
    -moz-transition: 100ms;
    -ms-transition: 100ms;
    -o-transition: 100ms;
  }
}