@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

@mixin itemStyles {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #2e2e2e;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  background-color: #ffffff;
}

.paginations-section-wrapper {
  .preload-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

  .pagination-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 30px);
    justify-content: center;
    gap: 10px;
    margin: 60px auto;
    align-items: end;

    &.pagination-dark-theme {

      .arrow {
        background-color: $secondary-bg-dark;
        border-color: $border-color-dark;

        path {
          stroke: $text-color-night;
        }

        &:hover {
          color: #000000;
          border-color: $primary-color-dark;
          background-color: $primary-color-dark;
  
          path {
            stroke: #000000;
          }
        }
      }

      .numberBtn {
        background-color: $secondary-bg-dark;
        border-color: $border-color-dark;
        color: $text-color-night;

        &:hover {
          color: #000000;
          border-color: $primary-color-dark;
          background-color: $primary-color-dark;
        }
      }

      .dotsBtn {
        border-bottom-color: $border-color-dark;
        color: $text-color-night;
      }

      .active {
        background-color: $primary-color-dark;
        border-color: $primary-color-dark;
        color: #000000;
      }
    }

    &.pagination-light-theme {

      .arrow {
        background-color: #ffffff;
        border-color: $border-color-light;

          path {
            stroke: $text-color-day;
          }

          &:hover {
            color: #fff;
            border-color: $primary-color;
            background-color: $primary-color;
    
            path {
              stroke: #ffffff;
            }
          }
      }

      .numberBtn {
        background-color: $secondary-bg-light;
        border-color: $border-color-light;
        color: $text-color-day;

        &:hover {
          color: #ffffff;
          border-color: $primary-color;
          background-color: $primary-color;
        }
      }

      .dotsBtn {
        border-bottom-color: $border-color-light;
        color: $text-color-day;
      }

      .active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #ffffff;
      }
    }

    .numberBtn {
      @include itemStyles;
      cursor: pointer;
      border-width: 1px;
      border-style: solid;
      box-shadow: 0 0 6px 0px #0000001f;
    }

    .dotsBtn {
      @include itemStyles;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      background-color: transparent;
    }

    .arrow {
      width: 100%;
      height: 100%;
      padding: 6px;
      border: 1px solid #c9c9c9;
      @include itemStyles;
      cursor: pointer;
    }

    .prev-arrow {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
}
  }
}