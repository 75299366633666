@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.corporate-report-wrapper {
  .corporate-report-block-search {
    .corporate-report-block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 70px;

      @include mediaMax($md) {
        gap: 30px;
      }

      @include mediaMax($lg) {
        grid-template-columns: 1fr;
      }

      h2 {
        text-align: center;
        letter-spacing: 0.6px;

        &.dark-theme {
          color: white;
        }
      }

      .corporate-report-text-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        justify-items: flex-end;
        text-align-last: center;

        @include mediaMax($md) {
          justify-content: space-between;
          gap: 16px;
        }

        .corporate-report-text {
          button {
            font-size: inherit;
            margin-bottom: 20px;
            cursor: pointer;
            text-align: center;
            color: $primary-color;
            display: inline-block;
            padding: 7px 3px 7px 3px;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border: none;
            cursor: pointer;
            transition: all;
            -webkit-transition: all;
            -moz-transition: all;
            -ms-transition: all;
            -o-transition: all;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);

            &.light-theme {
              background-color: $text-color-night;
              color: black;
            }

            &.dark-theme {
              background-color: rgb(75, 75, 75);
              color: $primary-background;
            }

            &:disabled {
              cursor: not-allowed;
              color: $primary-text-color;
            }

            &:disabled.dark-theme {
              cursor: not-allowed;
              color: $primary-background;
            }

          }

          .active.light-theme {
            background-color: $primary-color;
            color: $primary-background;
          }

          .active.dark-theme {
            background-color: #213c5f;
            color: $primary-background;
          }

          .active:active {
            transform: scale(0.97);
            -webkit-transform: scale(0.97);
            -moz-transform: scale(0.97);
            -ms-transform: scale(0.97);
            -o-transform: scale(0.97);
            outline: none;
            user-select: none;
          }

          .button-active {
            &:hover {
              background-color: $primary-color;
              color: $primary-background;
            }
          }
        }

        .corporate-report-amount {
          margin-top: 6px;

          p {
            padding-top: 1px;
            margin-bottom: 35px;

            &.dark-theme {
              color: white;
            }

            @include mediaMax($sm) {
              padding-top: 4px;
            }
          }
        }

        .corporate-report-amount p.active {
          color: $primary-color; // цвет активного значения
          font-weight: bold; // жирный шрифт для активного значения
        }
      }
    }

    .corporate-report-search {
      p {
        // color: grey;
        margin-bottom: 5px;

        &.dark-theme {
          color: white;
        }

        &.light-theme {
          color: rgb(5, 5, 5);
        }

        &.dark-theme {
          color: $primary-background;
        }
      }

      h2 {
        text-align: center;
        letter-spacing: 0.6px;
      }

      .corporate-report-search-date {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &.light-theme {
          color: rgb(0, 0, 0);
        }

        &.dark-theme {
          color: $primary-background;
        }

        @include mediaMax($md) {
          flex-direction: column;
        }

        .corporate-report-date {
          display: flex;
          flex-direction: column;

          &.light-theme {
            color: rgb(0, 0, 0);
          }

          &.dark-theme {
            color: $primary-background;
          }
        }
      }

      .corporate-report-search-details {
        display: flex;
        gap: 15px;

        // margin: 20px 0;
        &.light-theme {
          color: rgb(0, 0, 0);
        }

        &.dark-theme {
          color: $primary-background;
        }

        .corporate-report-details {
          display: flex;
          flex-direction: column;
          width: 100%;

          &.light-theme {
            color: rgb(0, 0, 0);
          }

          &.dark-theme {
            color: $primary-background;
          }

          @include mediaMax($md) {
            width: 100%;
          }

          &.light-theme {
            background: $primary-background;
          }

          &.dark-theme {
            background: rgb(52, 52, 52);
          }
        }
      }
    }

    .corporate-report-search-buttons {
      display: flex;
      gap: 10px;
      margin: 20px 0;

      @include mediaMax($sm) {
        flex-direction: column;
      }
    }
  }

  .line {
    margin: 15px 0;
    border: 0.1px solid $small-tag-color;
  }

  input {
    color: grey;
    width: 100%;

    @include mediaMax($md) {
      width: 100%;
    }
  }

  button {
    width: 100%;
    background-color: $hold-color;
  }
}