@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

$padding-block: clamp(20px, 4vw, 50px);
$shadow-block: 0px 0px 6px 0px #c5c5c5;

@mixin themeColorHandle($img-color, $filter) {
  .item-img-block {
    .arrow-img {
      path {
        fill: $img-color;
      }
    }

    .support-img {
      filter: $filter;
    }
  }
}

.travel-section {

  &.dark-theme-travel-section {
    @include themeColorHandle($error-color, invert(1))
  }

  &.light-theme-travel-section {
    @include themeColorHandle($primary-color, none)
  }

  .travel-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include mediaMax($md) {
      grid-template-columns: 1fr;
    }

    .item-img-block {
      position: relative;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mediaMax($md) {
        height: clamp(400px, 79vw, 640px);
      }

      .travel-title-block {
        padding: $padding-block;

        h1 {
          font-size: clamp(20px, 3vw, 34px);
          margin-bottom: 16px;
        }

        p {
          font-size: clamp(14px, 2vw, 18px);
          width: 48%;
          line-height: 1.5;

          @include mediaMax($lg) {
            width: 100%;
          }
        }
      }

      img {
        max-width: 100%;
      }

      .arrow-img {
        position: absolute;
        width: 4%;
        left: 99px;
        top: 140px;
        z-index: 10;

        @include mediaMax($xl) {
          left: 166px;
          top: 180px;
        }

        @include mediaMax($lg) {
          left: 34px;
          top: 225px;
          width: 15%;
        }

        @include mediaMax($md) {
          left: clamp(25px, 15vw, 113px);
          top: clamp(112px, 18vw, 142px);
          width: 6%;
        }
      }

      .support-img {
        position: absolute;
        right: 130px;
        top: 77px;
        z-index: 10;

        @include mediaMax($xl) {
          right: 107px;
          top: 120px;
        }

        @include mediaMax($lg) {
          right: 25px;
          top: 238px;
        }

        @include mediaMax($md) {
          right: clamp(23px, 13vw, 101px);
          top: clamp(116px, 18vw, 145px);
          width: 24%;
        }
      }

      .soft {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 80%;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;

        @include mediaMax($xl) {
          width: 100%;
          box-shadow: $shadow-block;
        }
      }

      .slide-block {
        min-width: 0;
      }
    }
  }
}